import React, { useState } from 'react';
import { Typography, TextareaAutosize, CircularProgress } from '@mui/material';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { updateCompletedTask } from '../../redux/slices/upload';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import { setCodeEdited } from '../../redux/reducers/isEditTaskCompleted';

interface CodeActionProps {
    actionValue: any[];
    result: any;
    actionIndex: number;
    action: string;
    projectStatus: string;
    heading?: string;
}

export default function CodeAction({ action, actionValue, result, actionIndex, projectStatus, heading }: CodeActionProps) {
    const isProjectStatusCompleted = projectStatus && projectStatus === 'COMPLETED'
    const [contents, setContents] = useState<any[]>(actionValue);
    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams();
    const [isEdited, setIsEdited] = useState(false);
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.upload)
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
    const [currentConfig, setcurrentConfig] = useState<any>(undefined)

    if (actionValue?.length === 0) {
        return <Typography>No code available.</Typography>;
    }

    const handleChange = (valueIndex: number, newValue: string) => {
        if (isProjectStatusCompleted) return;
        const updatedContents = [...contents];
        updatedContents[valueIndex] = {
            ...updatedContents[valueIndex],
            content: newValue
        };
        setContents(updatedContents);
        setIsEdited(true);
        dispatch(setCodeEdited(true));
    };

    const handleSave = () => {
        if (isProjectStatusCompleted) return;
        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: action,
            action_value: {
                id: contents[0].id,
                text: contents[0].content
            }
        };
        setcurrentConfig(actionIndex)
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setCodeEdited(false));
    }


    return (
        <>
            {contents.map((value: any, valueIndex: number) => {
                return (
                    <div key={valueIndex} style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold', marginRight: '10px', marginTop: '10px' }}>
                            {value.config_details?.value?.title}:
                        </Typography>
                        {(fetchPermissions?.completing_task_action_details?.[1] || "") === "read" ? (
                            <TextareaAutosize
                                id={`textarea-${valueIndex}`}
                                defaultValue={value.text}
                                disabled
                                minRows={4}
                                maxRows={15}
                                style={{ width: '64%', padding: '5px', border: '1px solid' }}
                            />
                        ) : (
                            <TextareaAutosize
                                id={`textarea-${valueIndex}`}
                                defaultValue={value.text}
                                onChange={(e) => handleChange(valueIndex, e.target.value)}
                                minRows={4}
                                maxRows={15}
                                style={{ width: '64%', padding: '5px', border: '1px solid' }}
                            />
                        )}

                    </div>
                )
            })}
            {!isProjectStatusCompleted && isEdited && (
                fetchPermissions?.completing_task_action_details?.[1] === "read" ? (
                    <></>
                ) : (
                    <AddButton
                        startIcon={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING ? <CircularProgress /> : null}
                        disabled={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING}
                        onClick={() => handleSave()}
                    >
                        Save
                    </AddButton>
                )
            )}

        </>
    );
}
