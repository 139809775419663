import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AnalyticsState } from '../interfaces/interface';
import { getAnalytics } from '../slices/analytics';

const ExtraReducers = (builder: ActionReducerMapBuilder<AnalyticsState>) => {
  builder
  .addCase(getAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
    state.getAnalyticsMessage = null
  })
  .addCase(getAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.analytics = action.payload;
    state.getAnalyticsMessage = null
  })
  .addCase(getAnalytics.rejected, (state, action:any) => {
    state.getStatus = STATUS.FAILED;
    state.getAnalyticsMessage = action.payload?.message || "An error occurred"
  });
};

export default ExtraReducers;
