import { ActionReducerMapBuilder, current, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AllocationState, taskDataProps } from '../interfaces/interface';
import { createAllocation } from '../slices/allocation';
import { getTaskData } from '../slices/taskData';

const ExtraReducers = (builder: ActionReducerMapBuilder<taskDataProps>) => {
  builder
    .addCase(getTaskData.pending, (state) => {
      state.getTaskDataStatus = STATUS.PENDING;
      state.getTaskDataMessage = null
    })
    .addCase(getTaskData.fulfilled, (state, action: PayloadAction<any>) => {
      state.taskData = action.payload; 
      state.getTaskDataStatus = STATUS.SUCESS; 
      console.log(action.payload.message)
      state.getTaskDataMessage = action.payload.message  || "Suceess"
    })
    .addCase(getTaskData.rejected, (state, action:any) => { 
      state.getTaskDataStatus = STATUS.FAILED;
      state.getTaskDataMessage = action.payload?.message || "An error occurred"
    })
};

export default ExtraReducers;
