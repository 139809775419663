import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { Dropzone, FileItem, ExtFile } from '@dropzone-ui/react';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createUpload, setCreateUploadStatus } from '../../redux/slices/upload/index';
import { AddButton } from '../button/button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { AnyAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { RootState } from '../../redux/store/appStore';

interface SheetData {
  name: string;
  data: any[][];
}

const SpreadsheetImportWizard = () => {
  const { createStatus } = useSelector((state: RootState) => state.upload);
  // const updateStatus = useSelector((state: RootState) => state.projects.updateStatus);

  const [files, setFiles] = useState<ExtFile[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [sheets, setSheets] = useState<SheetData[]>([]);
  const [previewTabIndex, setPreviewTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const createUploadMessage = useSelector((state: RootState) => state.upload.createUploadMessage)


  const handleDrop = (newFiles: ExtFile[]) => {
    setFiles(newFiles);
    if (newFiles.length > 0) {
      const file = newFiles[0].file;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = e.target?.result;
          if (typeof binaryStr === 'string') {
            setLoading(true);
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetDataArray = workbook.SheetNames.map(sheetName => {
              const worksheet = workbook.Sheets[sheetName];
              const sheetData = XLSX.utils.sheet_to_json<any[][]>(worksheet, { header: 1 });
              return { name: sheetName, data: sheetData };
            });
            setSheets(sheetDataArray);
            setPreviewTabIndex(0);
            setLoading(false);
          }
        };
        reader.readAsBinaryString(file);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handlePreviewTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPreviewTabIndex(newValue);
  };

  const handleNextTab = () => {
    if (tabIndex < 1) {
      setTabIndex(tabIndex + 1);
    }
  };

  const handlePreviousTab = () => {
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  const handleSubmit = () => {
    console.log("HANDLE SUMBIT")
    if (files.length > 0 && id) {
      const file = files[0].file;
      if (file) {
        dispatch(createUpload({ id, selectedFile: file }) as unknown as AnyAction)
      }
    }
    else {
      handleSnackbar('Please upload a file to submit', 'warning');
    }
  };

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar(createUploadMessage, 'success');
        setTimeout(() => {
          navigate(`/project/${id}?tab=task`);
        }, 1000);
      }
      if (createStatus === STATUS.FAILED) {
        handleSnackbar(createUploadMessage, 'error');
      }
    }
    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS || createStatus === STATUS.FAILED) {
        dispatch(setCreateUploadStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const convertArray = (arr: any[], length: number) => {
    let gapIndices: any = []
    let lastIndex = 0
    let duplicateArr = arr.concat([])
    arr.map((item, index) => {
      if ((index - lastIndex) <= 1) {
      } else {
        gapIndices.push([lastIndex + 1, index - 1])
      }
      lastIndex = index
    })

    gapIndices.map((item: any) => {
      arr.splice(item[0], item[1] - item[0] + 1, ...(new Array(item[1] - item[0] + 1)))
    })


    if (length == duplicateArr.length) {

    } else {
      arr = [...arr, ...(new Array(length - duplicateArr.length))]
    }

    return arr
  }

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        centered
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          "& .MuiTabs-indicator": { backgroundColor: "#3D85C6" },
        }}
      >
        <Tab
          label="1. Upload"
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#333",
            "&.Mui-selected": { color: "#3D85C6" },
          }}
        />
        <Tab
          label="2. Preview & Submit"
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#333",
            "&.Mui-selected": { color: "#3D85C6" },
          }}
        />
      </Tabs>

      {tabIndex === 0 && (
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
        >
          <Dropzone
            onChange={handleDrop}
            accept={".csv,.tsv,.xls,.xlsx,.xml"}
            maxFiles={1}
            header={false}
            footer={false}
            style={{
              border: "2px dashed #4a90e2",
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "#ffffff",
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {files.length === 0 ? (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Upload a file here
                  </Typography>
                  <Typography variant="body2" sx={{ textTransform: 'none', fontSize: '14px', color: '#333', marginTop: '10px' }}>
                    You can upload .csv,.xls,.xlsx
                  </Typography>
                  <AddButton>Choose a file</AddButton>
                </div>
              </>
            ) : (
              files.map((file, index) => (
                <FileItem
                  key={index}
                  file={file.file}
                  onDelete={() => setFiles(files.filter((_, i) => i !== index))}
                />
              ))
            )}
          </Dropzone>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Box mt={2} display="flex" justifyContent="space-around" >
              <Box display="flex" alignItems="center" width="100%">
                <Box flexGrow={1} borderBottom="1px solid black" />
                <Typography variant="body1" mx={2}>
                  OR
                </Typography>
                <Box flexGrow={1} borderBottom="1px solid black" />
              </Box>
            </Box>
            <div style={{ display: 'flex', marginLeft: '10px' }}>
              <AddButton disabled><FileUploadIcon />Create Manually</AddButton>
              <AddButton component="a" href="https://data-platform-resources.s3.ap-south-1.amazonaws.com/web-platform-assets/Task+Upload+Template.xlsx"><FileDownloadIcon /> Download Template</AddButton>
            </div>
          </div>
          <br />
          <Box mt={2} display="flex" justifyContent="flex-end" width="100%" sx={{ marginBottom: '10px' }}>
            <AddButton onClick={handleNextTab}>Next</AddButton>
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box mt={2}>
          {sheets.length > 0 ? (
            <Box>
              <TableContainer
                component={Paper}
                sx={{ mt: 2, maxHeight: 400 }}
              >
                <Table stickyHeader sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      {sheets[previewTabIndex]?.data?.[0]?.map((cell, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            backgroundColor: "#f1f3f4",
                            fontWeight: "bold",
                            border: "1px solid #e0e0e0",
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sheets[previewTabIndex].data
                      .slice(1)
                      .map((row, rowIndex) => {
                        if (
                          row.length > 0 &&
                          sheets[previewTabIndex].data[0].length >= row.length
                        ) {
                          row = convertArray(
                            row,
                            sheets[previewTabIndex].data[0].length
                          );
                        }
                        return (
                          <TableRow key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                              <TableCell
                                key={cellIndex}
                                sx={{
                                  border: "1px solid #e0e0e0",
                                  textAlign: "center",
                                  padding: "8px",
                                }}
                              >
                                {cell}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Tabs
                value={previewTabIndex}
                onChange={handlePreviewTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderTop: 1, borderColor: "divider", mt: 2 }}
              >
                {sheets.map((sheet, index) => (
                  <Tab
                    key={index}
                    label={sheet.name}
                    style={{
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                    className="Mui-selected"
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No data to display. Please upload a file first.
            </Typography>
          )}
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            sx={{ marginBottom: "10px" }}
          >
            <AddButton onClick={handlePreviousTab}>Previous</AddButton>
            <AddButton
              disabled={
                id
                  ? createStatus == STATUS.PENDING
                  : createStatus == STATUS.PENDING
              }
              startIcon={
                id ? (
                  createStatus == STATUS.PENDING ? (
                    <CircularProgress size={17} />
                  ) : null
                ) : createStatus == STATUS.PENDING ? (
                  <CircularProgress />
                ) : null
              }
              onClick={handleSubmit}
            >
              Submit Task
            </AddButton>
          </Box>
        </Box>
      )}
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default SpreadsheetImportWizard;
