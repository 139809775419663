import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { DeliveredState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/deliveredFileExtraReducer';

const initialState: DeliveredState = {
  delivered: {
    count: undefined,
    results: []
  },
  createDeliveredStatus: STATUS.NOT_STARTED,
  createUploadDeliveredFileMessage: ""
};

export const createUploadDeliveredFile = createAsyncThunk(
  'task/mark/delivered/company/POST',
  async (payload: { id: string, selectedFile: File }, thunkAPI) => {
    try {
      const { id, selectedFile } = payload;
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await api.post(`task/mark/delivered/company/?project_id=${id}`, formData);
        return response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

const MarkDelivereduploadSlice = createSlice({
  name: 'delivered',
  initialState,
  reducers: {
    setCreateDeliveredStatus(state, action) {
      state.createDeliveredStatus = action.payload
    },
  },
  extraReducers
});

export const { setCreateDeliveredStatus } = MarkDelivereduploadSlice.actions
export default MarkDelivereduploadSlice.reducer;
