import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import TaskDetailsAccordion from './accordianDetails';
import { getCompletedTask } from '../../redux/slices/upload';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import { CircularProgress } from '@mui/material';

export default function TaskCompleted({taskID}:{taskID:string}) {
    const taskDetails = useSelector((state: RootState) => state.upload.taskCompleted?.data?.results);
    const tasks = useSelector((state:RootState) => state.upload?.upload?.results)
    const dispatch = useDispatch<AppDispatch>();
    const {id} = useParams();
    const { getStatus } = useSelector((state:RootState) => state.upload)
    const association_type = useSelector((state: RootState) => state.projects.association_type);
    
    useEffect(()=>{
        dispatch(getCompletedTask({project_id: id, task_id: taskID, association_type: association_type}))
    },[dispatch, id, taskID])

    return (
        (
            getStatus === STATUS.PENDING ? 
                <div style={{textAlign:'center'}} >
                    <CircularProgress />
                </div>
            :   getStatus === STATUS.SUCESS ? 
                    taskDetails && taskDetails.length === 0 ?  
                        <div style={{display: "flex", justifyContent:"center", alignItems: "center", fontWeight: "bold", fontSize: "24px", height: "100%"}}>Task Not Completed</div> 
                    :   <div>
                            {taskDetails &&taskDetails.filter((task: any) => task.type === 'TASK').map((result: any, resultIndex: any) => (
                                <TaskDetailsAccordion key={resultIndex} result={result} resultIndex={resultIndex} />
                            ))}
                        
                            {taskDetails &&taskDetails.filter((task: any) => task.type === 'REVIEW').map((result: any, resultIndex: any) => (
                                <TaskDetailsAccordion key={resultIndex} result={result} resultIndex={resultIndex} />
                            ))}
                        </div> 
            :  getStatus === STATUS.FAILED ?
                <div>Failed to Fetch the Details. Please contact admin.</div>
            : <></>
        )
    );
}
