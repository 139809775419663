import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { GetVendorPayload, GetVendorsListPayload, updateVendorPayload } from '../../interfaces/interface';
interface vendorInitialStateProps {
  vendors: {
    count: number;
    results: any;
  };
  createVendorStatus: STATUS,
  deleteVendorStatus: STATUS,
  blockedVendorStatus: STATUS
  updateVendorStatus: STATUS,
  getVendorsStatus: STATUS,
  onBoardNewVendorAndAdminStatus: STATUS,
  ListAllVendorsStatus: STATUS,
  ListOnBoardVendors: { results: any },
  ListOnBoardAdmins: { results: any }
  fetchAdminStatus: STATUS,
  fetchVendorStatus: STATUS,
  createVendorMessage: any,
  getVendorMessage: any,
  deleteVendorMessage: any,
  fetchAdminMessage: any,
  fetchVendorsMessage: any,
  onBoardNewVendorAndAdminMessage: any,
  updateVendorMessage: any
}


const initialState: vendorInitialStateProps = {
  vendors: { count: 10, results: [] },
  createVendorStatus: STATUS.NOT_STARTED,
  deleteVendorStatus: STATUS.NOT_STARTED,
  blockedVendorStatus: STATUS.NOT_STARTED,
  updateVendorStatus: STATUS.NOT_STARTED,
  getVendorsStatus: STATUS.NOT_STARTED,
  onBoardNewVendorAndAdminStatus: STATUS.NOT_STARTED,
  ListAllVendorsStatus: STATUS.NOT_STARTED,
  ListOnBoardVendors: { results: [] },
  fetchAdminStatus: STATUS.NOT_STARTED,
  ListOnBoardAdmins: {
    results: []
  },
  fetchVendorStatus: STATUS.NOT_STARTED,
  createVendorMessage: "",
  getVendorMessage: "",
  deleteVendorMessage: "",
  fetchAdminMessage: "",
  fetchVendorsMessage: "",
  onBoardNewVendorAndAdminMessage: "",
  updateVendorMessage: ""
};

export const createVendorAction = createAsyncThunk(
  'vendor/company/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('company/vendor/', payload);
      const { status, data } = response;
      if (status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;  
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
)

// export const updateVendorAction = createAsyncThunk(
//   'company/vendor/patch',
//   async(payload: any, thunkAPI) => {
//     const response = await api.patch(`company/vendor/`, payload);
//     const {status, data} = response
//     if(status === 200){
//       return data;
//     }else{
//       return thunkAPI.rejectWithValue({response, data})
//     }
//   }
// )

export const getVendorAction = createAsyncThunk(
  'vendors/getVendor',
  async (payload: GetVendorPayload, thunkAPI) => {
    const { paginate, page, pageSize, sort, ordering, search_query, is_deleted, is_vendorblock } = payload;
    const queryString = new URLSearchParams({
      ...(paginate && { paginate }),
      ...(search_query && { search_query }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(ordering && { ordering }),
      ...(is_deleted && { is_deleted }),
      ...(is_vendorblock && { is_vendorblock }),
    }).toString();
    try {
      const res = await api.get(`company/vendor?${queryString}`)
      const { status, data } = res
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ res, data })
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.res?.data || "Failed to fetch vendors")
    }
  }
)

export const deleteVendorAction = createAsyncThunk(
  'vendors/deleteOnBoardVendor',
  async (payload: updateVendorPayload, thunkAPI) => {
    try {
      const res = await api.patch(`project/onboarding/`, payload);
      const { status, data } = res;

      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ status, data });
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to delete vendor");
    }
  }
);


export const fetchAdminsAction = createAsyncThunk(
  'vendors/getListAdmins',
  async (payload: GetVendorsListPayload, thunkAPI) => {
    const { paginate, page, pageSize, project_id, sort, search_query } = payload;
    const queryString = new URLSearchParams({
      ...(paginate !== undefined && { paginate: String(paginate) }),
      ...(search_query && { search_query }),
      ...(project_id && { project_id }),
      ...(page !== undefined && { page: page.toString() }),
      ...(pageSize !== undefined && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
    }).toString();

    try {
      const res = await api.get(`project/onboarding?${queryString}`);
      const { status, data } = res;

      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ res, data });
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.res?.data || "Failed to fetch Admins");
    }
  }
);

export const fetchVendorsAction = createAsyncThunk(
  'vendors/fetchVendors',
  async (payload: GetVendorsListPayload, thunkAPI) => {
    const { paginate, page, pageSize, project_id, sort, search_query } = payload;
    const queryString = new URLSearchParams({
      ...(paginate !== undefined && { paginate: String(paginate) }),
      ...(search_query && { search_query }),
      ...(project_id && { project_id }),
      ...(page !== undefined && { page: page.toString() }),
      ...(pageSize !== undefined && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
    }).toString();
    try {
      const res = await api.get(`project/onboarding/vendor?${queryString}`)
      const { status, data } = res
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ res, data })
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.res?.data || "Failed to fetch vendors")
    }

  }
)

export const onBoardNewVendorAndAdminAction = createAsyncThunk(
  'vendor/newVendor', async (payload: any, thunkAPI) => {
    try {
      const res = await api.post(`/project/onboarding/`, payload);
      const { status, data } = res;
      if (status === 201) {
        return data
      } else {
        return thunkAPI.rejectWithValue({ res, data })
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || "An error occurred")
    }
  }
)

export const updateVendorAction = createAsyncThunk(
  'vendors/deleteVendor', async (payload: any, thunkAPI) => {
    try {
      const res = await api.patch(`/company/vendor/`, payload);
      const { status, data } = res;
      if (status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue({ res, data })
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.res?.data || "An error occurred")
    }
  }
)

const addVendorSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    setCreateVendorStatus(state, action) {
      state.createVendorStatus = action.payload
    },
    setUpdateVendorStatus(state, action) {
      state.updateVendorStatus = action.payload
    },
    setGetVendorStatus(state, action) {
      state.getVendorsStatus = action.payload
    },
    setOnBoardNewVendorAndAdminStatus(state, action) {
      state.onBoardNewVendorAndAdminStatus = action.payload
    },
    setUpdateVendorAndAdminStatus(state, action) {
      state.deleteVendorStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVendorAction.pending, (state) => {
        state.createVendorStatus = STATUS.PENDING;
      })
      .addCase(createVendorAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.vendors.results = [...(current(state).vendors.results), action.payload];
        state.vendors.count = current(state).vendors.count + 1
        state.createVendorStatus = STATUS.SUCESS;
      })
      .addCase(createVendorAction.rejected, (state, action) => {
        state.createVendorStatus = STATUS.FAILED;
      })

      .addCase(getVendorAction.pending, (state) => {
        state.getVendorsStatus = STATUS.PENDING;
      })
      .addCase(getVendorAction.fulfilled, (state, action) => {
        state.vendors = action.payload
        state.getVendorsStatus = STATUS.SUCESS;
      })
      .addCase(getVendorAction.rejected, (state) => {
        state.getVendorsStatus = STATUS.FAILED
      })

      .addCase(updateVendorAction.pending, (state) => {
        state.updateVendorStatus = STATUS.PENDING
      })
      .addCase(updateVendorAction.fulfilled, (state, action) => {
        state.vendors.results = current(state).vendors.results.filter((vendor: any) => vendor.id !== action.payload.data.id)
        state.vendors.count -= 1
        state.updateVendorStatus = STATUS.SUCESS
      })
      .addCase(updateVendorAction.rejected, (state, action) => {
        state.updateVendorStatus = STATUS.FAILED
      })

      .addCase(onBoardNewVendorAndAdminAction.pending, (state, action) => {
        state.onBoardNewVendorAndAdminStatus = STATUS.PENDING
      })
      .addCase(onBoardNewVendorAndAdminAction.fulfilled, (state, action) => {
        state.onBoardNewVendorAndAdminStatus = STATUS.SUCESS
        state.onBoardNewVendorAndAdminMessage = action.payload?.message
      })
      .addCase(onBoardNewVendorAndAdminAction.rejected, (state, action:any) => {
        state.onBoardNewVendorAndAdminStatus = STATUS.FAILED
        state.onBoardNewVendorAndAdminMessage = action.payload || "An error occurred"
        
      })

      .addCase(fetchVendorsAction.pending, (state, action) => {
        state.fetchVendorStatus = STATUS.PENDING
      })
      .addCase(fetchVendorsAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.ListOnBoardVendors.results = action.payload;
        state.fetchVendorStatus = STATUS.SUCESS
      })
      .addCase(fetchVendorsAction.rejected, (state, action) => {
        state.fetchVendorStatus = STATUS.FAILED
      })

      .addCase(fetchAdminsAction.pending, (state, action: PayloadAction<any>) => {
        state.fetchAdminStatus = STATUS.PENDING
      })
      .addCase(fetchAdminsAction.fulfilled, (state, action) => {
        state.ListOnBoardAdmins.results = action.payload
        state.fetchAdminStatus = STATUS.SUCESS
      })
      .addCase(fetchAdminsAction.rejected, (state, action) => {
        state.fetchAdminStatus = STATUS.FAILED
      })

      .addCase(deleteVendorAction.pending, (state, action) => {
        state.deleteVendorStatus = STATUS.PENDING
      })
      .addCase(deleteVendorAction.fulfilled, (state, action) => {
        state.vendors.results = current(state).vendors.results.filter((vendor: any) => vendor.id !== action.payload.data.id)
        state.vendors.count -= 1
        state.deleteVendorStatus = STATUS.SUCESS
        state.deleteVendorMessage = action.payload?.message
      })
      .addCase(deleteVendorAction.rejected, (state, action:any) => {
        state.deleteVendorStatus = STATUS.FAILED
        state.deleteVendorMessage = action.payload?.message
      })


  }

});

export const { setCreateVendorStatus, setUpdateVendorAndAdminStatus, setUpdateVendorStatus, setGetVendorStatus, setOnBoardNewVendorAndAdminStatus } = addVendorSlice.actions
export default addVendorSlice.reducer;
