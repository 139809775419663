import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Collapse, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Popover, Radio, Tab, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { AddButton } from '../button/button';
import { STATUS } from '../../utils/status';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestoreIcon from '@mui/icons-material/Restore';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const Filter = ({ anchorE3, setAnchorE3, setSearchType,
    value, handleChange, handleTaskNameToggle,
    openList, resetTaskName, filterInFocus,
    taskName, handleFieldChange, setfilterInFocus,
    handleTaskEmailNameToggle, resetTaskEmailName,
    handleByTaskListClick, taskAllocation, resetTaskType,
    selectedTaskType, handleTaskTypeSelect, handleByReviewListClick,
    byReviewListOpen, resetStatus, ByReviewListItems, handleStatusToggle,
    selectedStatus, resetSelectVendor, openAutoComplete, handleAutoCompleteOpen,
    handleAutoCompleteClose, selectedVendor, options, setVendorId, setSelectedVendor,
    loading, handleAutocompleteInput, handleBySelectVendorClick, handleApplyClick, handleReset }: any) => {



    const association_type = useSelector((state: RootState) => state.projects.association_type);
    const association_type_company = association_type && association_type === 'COMPANY' ? true : false;
    const getVendorStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

    // const handleReset = () => {
    //     window.location.reload()
    // }
    const handleClose = () => {
        if (setAnchorE3) setAnchorE3(null);
        if (setSearchType) setSearchType("")
    };

    return (
        <>

            <Popover
                // id={id}
                open={Boolean(anchorE3)}
                anchorEl={anchorE3}
                onClose={handleClose}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Box sx={{ width: "330px", typography: "body1", height: "337px" }}>
                    <TabContext value={value}>
                        <Box
                            sx={{
                                borderBottom: 2,
                                borderColor: "divider",
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 1,
                            }}
                        >
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="Filters" value="1" />
                                <div style={{ position: "absolute", left: "70px", top: "5px" }}>
                                    <IconButton
                                        onClick={handleClose}
                                        color="primary"
                                        aria-label=""
                                        style={{ marginLeft: "14rem" }}
                                    >
                                        <CancelIcon color="primary" />
                                    </IconButton>
                                </div>
                                {/* <Tab label="Tasks Filters" value="2" /> */}
                            </TabList>
                        </Box>

                        <List sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', marginLeft: "10px" }}>
                            {/* Task Name List Item */}
                            <div onClick={() => handleTaskNameToggle("taskName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ListItemText primary={<Typography style={{fontSize: "16px", fontFamily: "montserrat"}}>Task name</Typography>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                                {openList === "taskName" ? <ExpandLess /> : <ExpandMore />}
                                <Button onClick={resetTaskName} style={{ padding: 0, minWidth: 'auto' }}>
                                    <Tooltip title="Reset task name" arrow>
                                        <BlockIcon fontSize="small" color="error" />
                                    </Tooltip>
                                </Button>
                            </div>

                            <Collapse in={openList === "taskName"} timeout="auto" unmountOnExit>
                                <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {/* Text Field */}
                                    <TextField
                                        autoFocus={filterInFocus === "task_name"}
                                        id="outlined-basic"
                                        value={taskName}
                                        size='small'
                                        label=""
                                        variant="outlined"
                                        onChange={(e) => handleFieldChange("taskName", e)}
                                        style={{ width: "260px", paddingLeft: "20px" }}
                                        onBlur={() => setfilterInFocus("")}
                                        onFocus={() => setfilterInFocus("task_name")}
                                        autoComplete="off"
                                    />
                                </div>
                            </Collapse>
                        </List>

                        <Divider
                            orientation="horizontal"
                            variant="fullWidth"
                            flexItem
                        />

                        <List sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', marginLeft: "10px" }}>
                            {/* Tasker Email/Name List Item */}
                            <div onClick={() => handleTaskEmailNameToggle("taskEmailName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ListItemText primary={<Typography style={{fontSize: "16px", fontFamily: "montserrat"}}>Email / Name</Typography>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                                {openList === "task_allocation" ? <ExpandLess /> : <ExpandMore />}
                                <Button onClick={resetTaskEmailName} style={{ padding: 0, minWidth: 'auto' }}>
                                    <Tooltip title="Reset name / email" arrow>
                                        <BlockIcon fontSize="small" color="error" />
                                    </Tooltip>
                                </Button>
                            </div>

                            <Collapse in={openList === "taskEmailName"} timeout="auto" unmountOnExit>
                                <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {/* Text Field */}
                                    <TextField
                                        autoFocus={filterInFocus === "task_allocation"}
                                        id="outlined-basic"
                                        size='small'
                                        value={taskAllocation}
                                        label=""
                                        variant="outlined"
                                        onChange={(e) => handleFieldChange("alloc", e)}
                                        style={{ width: "260px", paddingLeft: "20px" }}
                                        onBlur={() => setfilterInFocus("")}
                                        onFocus={() => setfilterInFocus("task_allocation")}
                                        autoComplete="off"
                                    />
                                </div>
                            </Collapse>
                        </List>

                        <Divider
                            orientation="horizontal"
                            variant="fullWidth"
                            flexItem
                        />

                        {/* <List sx={{ width: '100%', maxWidth: 320, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  <div onClick={() => handleReviewerNameToggle("reviewAllocation")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Reviewer email / name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: "montserrat" }} />
                    {openList === "reviewAllocation" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetReviewAllocation} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset reviewer name" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "reviewAllocation"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <TextField
                        autoFocus={filterInFocus === "review_allocation"}
                        id="outlined-basic"
                        value={reviewAllocation}
                        size='small'
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("reviewAllocation", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("review_allocation")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List> */}

                        {/* <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                /> */}

                        {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  <div onClick={() => handlePseudoNameToggle("pseudoName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Pseudo name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "pseudoName" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetPseudoName} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset pseudo name" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "pseudoName"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <TextField
                        autoFocus={filterInFocus === "pseudo_name"}
                        id="outlined-basic"
                        value={pseudoname}
                        size='small'
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("pseudoName", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("pseudo_name")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List> */}

                        <List
                            sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', marginLeft: "10px" }}
                            component="div"
                        >
                            <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-around" }} onClick={() => handleByTaskListClick("taskList")} >
                                <ListItemText primary={<Typography style={{fontSize: "16px", fontFamily: "montserrat"}}>Task type</Typography>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                                {openList === "taskList" ? <ExpandLess /> : <ExpandMore />}
                                <Button onClick={resetTaskType} style={{ padding: 0, minWidth: 'auto' }}>
                                    <Tooltip title="Reset task type" arrow>
                                        <BlockIcon fontSize="small" color="error" />
                                    </Tooltip>
                                </Button>
                            </div>

                            <Collapse in={openList === "taskList"} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {['Task', 'Review'].map((type) => (
                                        <ListItemButton key={type} sx={{ pl: 4 }} onClick={() => handleTaskTypeSelect(type)}>
                                            <ListItemIcon>
                                                <Radio
                                                    size='small'
                                                    edge="start"
                                                    checked={selectedTaskType === type}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={type} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </List>

                        <Divider
                            orientation="horizontal"
                            variant="fullWidth"
                            flexItem
                        />

                        {/* Status Section */}
                        <List sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', marginLeft: "10px" }}
                            component="div"
                        >
                            <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-between", alignItems: "center" }} onClick={() => handleByReviewListClick("status")}>
                                <ListItemText primary={<Typography style={{fontSize: "16px", fontFamily: "montserrat"}}>Tasking Status</Typography>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                                {openList === "status" && byReviewListOpen ? <ExpandLess /> : <ExpandMore />}
                                <Button onClick={resetStatus} style={{ padding: 0, minWidth: 'auto' }}>
                                    <Tooltip title="Reset status" arrow>
                                        <BlockIcon fontSize="small" color="error" />
                                    </Tooltip>
                                </Button>
                            </div>
                            <Collapse in={openList === "status"} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {ByReviewListItems.map((item: any, index: any) => {
                                        return (
                                            <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => handleStatusToggle(item)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        size='small'
                                                        edge="start"
                                                        checked={selectedStatus.includes(item)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={item} />
                                            </ListItemButton>
                                        )
                                    })}
                                </List>
                            </Collapse>

                        </List>

                        <Divider
                            orientation="horizontal"
                            variant="fullWidth"
                            flexItem
                            style={{ marginBottom: "5px" }}
                        />
                        {association_type_company &&
                            <List disablePadding sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', marginLeft: "10px" }}
                                component="div">
                                <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-between", alignItems: "center" }} onClick={() => handleBySelectVendorClick("selectVendor")}>
                                    <ListItemText primary={<Typography style={{fontSize: "16px", fontFamily: "montserrat"}}>Select Vendor</Typography>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                                    {openList === "selectVendor" ? <ExpandLess /> : <ExpandMore />}
                                    <Button onClick={resetSelectVendor} style={{ padding: 0, minWidth: 'auto' }}>
                                        <Tooltip title="Reset vendor">
                                            <BlockIcon fontSize="small" color="error" />
                                        </Tooltip>
                                    </Button>
                                </div>
                                <Collapse in={openList === "selectVendor"} timeout="auto" unmountOnExit>
                                    <div style={{ marginLeft: "20px" }}>
                                        <Autocomplete
                                            size='small'
                                            sx={{ width: 300 }}
                                            open={openAutoComplete}
                                            onOpen={handleAutoCompleteOpen}
                                            onClose={handleAutoCompleteClose}
                                            // disabled={selectedRow}
                                            autoSelect
                                            value={selectedVendor}
                                            getOptionLabel={(option) =>
                                                option?.pseudo_name ? option?.pseudo_name : option?.vendor_pseudo_name
                                                // option.vendor_pseudo_name ? `${option.vendor_name} (${option.vendor_pseudo_name})` : option.vendor_name
                                            }
                                            // filterOptions={(options) =>
                                            //     options.filter(option => option?.pseudo_name)
                                            // }
                                            options={
                                                options
                                                    .map((a: any) => { return { ...a, vendor_pseudo_name_small: (a.pseudo_name?.toLowerCase() || a.vendor_pseudo_name?.toLowerCase()) } })
                                                    .filter((a: { vendor_id: string; }) => a.vendor_id != "")
                                                    .sort((a: { vendor_pseudo_name_small: number; }, b: { vendor_pseudo_name_small: number; }) => a.vendor_pseudo_name_small < b.vendor_pseudo_name_small ? -1 : a.vendor_pseudo_name_small > b.vendor_pseudo_name_small ? 1 : 0)
                                            }
                                            loading={getVendorStatus === STATUS.PENDING}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    setVendorId(value.vendor_id);
                                                    setSelectedVendor(value);
                                                } else {
                                                    setSelectedVendor(null);
                                                }
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Vendor Pseudo Name"
                                                        size="small"
                                                        sx={{ width: '85%' }}
                                                        value={selectedVendor != null ? selectedVendor.name : ''}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        onChange={(e) => { handleAutocompleteInput(e) }}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </Collapse>
                            </List>
                        }

                    </TabContext>

                    <Divider
                        orientation="horizontal"
                        variant="fullWidth"
                        flexItem
                        style={{ marginBottom: "10px", marginTop: "5px" }}
                    />

                    {/* <div style={{ display: "flex", justifyContent: "flex-end", gap: "15px", padding: "10px" }}> */}
                    {/* <div
                  style={{
                    backgroundColor: "white",
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1,
                    height: "50px",
                    paddingTop: "0px",
                  }}
                >
                  <IconButton aria-label="" color="secondary">
                    <Tooltip title={"Reset"} arrow>
                    <RestoreIcon onClick={handleReset} />
                    </Tooltip>
                  </IconButton> */}

                    {/* <Button
                    style={{
                      position: "sticky",
                      bottom: 0,
                      left: "276px",
                      zIndex: 1,
                      top: "0px",
                    }}
                    variant="contained"
                    startIcon={<FilterAltIcon />}
                    onClick={handleApplyClick}
                  >
                    Apply
                    
                  </Button> */}
                    {/* </div> */}


                    {/* Apply Button  */}

                    <div
                        style={{
                            backgroundColor: "white",
                            position: "sticky",
                            bottom: 0,
                            zIndex: 1,
                            height: "50px",
                            paddingTop: "0px",
                        }}
                    >
                        <IconButton aria-label="" color="secondary">
                            <Tooltip title={"Reset"} arrow>
                                <RestoreIcon onClick={handleReset} />
                            </Tooltip>
                        </IconButton>
                        <Button
                            style={{
                                position: "sticky",
                                bottom: 0,
                                left: "230px",
                                zIndex: 1,
                                top: "0px",
                            }}
                            variant="contained"
                            startIcon={<FilterAltIcon />}
                            onClick={handleApplyClick}
                        >
                            Apply
                            {/* {filterCount > 0 ? (filterCount) : (null)} */}
                        </Button>
                        {/* </div> */}
                    </div>
                </Box>
            </Popover>
        </>
    )
}

export default Filter