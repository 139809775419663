import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../button/button';
import { TableCellHead } from '../table/tableCell';
import { useStyles } from '../../assets/styles/listTableStyle';
import TableRowItem from './configTableRowItem';
import { getConfigData, getConfigForTable, updateConfig } from '../../redux/slices/config';
import DeleteModal from '../modal/deleteModal';
import Error from '../error/error';
import AddIcon from '@mui/icons-material/Add';

interface ConfigTableProps {
  onAddConfigClick: () => void;
}

const ConfigTable: React.FC<ConfigTableProps> = ({ onAddConfigClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams()

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");

  const config = useSelector((state: RootState) => state.config.config);
  const getStatus = useSelector((state: RootState) => state.config.getConfigStatus);

  const pathh = window.location.pathname;
  const segment = pathh.split("/")
  const configId = segment[4]

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await dispatch(updateConfig({ id: userIdToDelete, is_deleted: true }));
      dispatch(
        getConfigForTable({ pageSize: itemsPerPage, project_id: id || '', id: configId })
      );
    } catch (error) {
      console.error('Failed to delete config', error);
    }
  };

  useEffect(() => {
    dispatch(getConfigForTable({ search: searchTerm, page: currentPage, pageSize: itemsPerPage, project_id: id || '', }))
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage, id]);


  const handleAddClick = () => {
    navigate(`/addconfig/${id}`)
    dispatch(getConfigData({}));
  };

  const handleEditClick = (row: any) => {
    dispatch(getConfigData(row));
    navigate(`/editconfig/${id}/${row.config_details.id}/${row.id}`);
  };

  useEffect(() => {
    const handleGetStatus = () => {
      if (getStatus === STATUS.PENDING) {
        setLoading(true)
      }
      if (getStatus === STATUS.SUCESS) {
        setLoading(false)
      }
      if (getStatus === STATUS.FAILED) {
        setLoading(false)
      }
    };

    handleGetStatus();
  }, [getStatus])

  const headers = ["ORDER", "TYPE", "CONFIG TYPE", "REQUIRED", "VALUE", "ACTIONS"];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px' }}>Config Table</Typography>
        <AddButton onClick={handleAddClick}><AddIcon fontSize='small' sx={{ marginRight: '2px' }} /> Add Config</AddButton>
      </div>
      <TableContainer>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                {headers.map((header) => (
                  <TableCellHead key={header}>{header}</TableCellHead>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getStatus === STATUS.SUCESS && config && config.results && config.results.length > 0 ? (
                config.results.map((row: any) => (
                  <TableRowItem
                    key={row.id}
                    row={row}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There are no configs added as of yet. You can add a config to see it here.'} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default ConfigTable;
