import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AnalyticsExpertState } from '../interfaces/interface';
import { getExpertAnalytics, getExpertAnalyticsDownload } from '../slices/analytics/experts';

const ExtraReducers = (builder: ActionReducerMapBuilder<AnalyticsExpertState>) => {
  builder
  .addCase(getExpertAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
    state.getExpertAnalyticsMessage = null
  })
  .addCase(getExpertAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.analyticsExpert = action.payload;
    state.getExpertAnalyticsMessage = null
  })
  .addCase(getExpertAnalytics.rejected, (state, action:any) => {
    state.getStatus = STATUS.FAILED;
    state.getExpertAnalyticsMessage = action.payload?.message || "An error occrred"
  })
  .addCase(getExpertAnalyticsDownload.pending, (state) => {
    state.downloadAnalyticsByExpertStatus = STATUS.PENDING;
    state.getExpertAnalyticsDownloadMessage = null
  })
  .addCase(getExpertAnalyticsDownload.fulfilled, (state, action) => {
    state.downloadAnalyticsByExpertStatus = STATUS.SUCESS;
    state.analyticsExpertDownload = action.payload.data;
    state.getExpertAnalyticsDownloadMessage = null
  })
  .addCase(getExpertAnalyticsDownload.rejected, (state, action: any) => {
    state.downloadAnalyticsByExpertStatus = STATUS.FAILED;
    state.getExpertAnalyticsDownloadMessage = action.payload?.message || "An error occurred";
  });
};

export default ExtraReducers;
