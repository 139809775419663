import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { getCookie } from './components/login/cookies';
import LoginPage from './routes/login/LoginPage';
import HomePage from './components/sidebar/homePage';
import DashboardMain from './routes/dashboard/dashboardMain';
import BulkUploadTask from './routes/bulkupload/bulkUploadTask';
import ProjectListMain from './routes/projects/projectListMain';
import ProjectMain from './routes/addProject/projectMain';
import ExpertMain from './routes/addexpert/expertsMain';
import ExpertListMain from './routes/experts/expertListMain';
import ProjectTab from './components/projectList/projectListTab';
import UserMain from './routes/adduser/userMain';
import UserListMain from './routes/users/userListMain';
import ConfigMain from './routes/addConfig/configMain';
import TaskUploadMain from './routes/uploadtask/taskUploadMain';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store/appStore';
import { getUserAccess } from './redux/slices/company';
import TaskCompletedMain from './components/taskCompleted/taskCompletedMain';
import CustomizedSteppers from './components/multiStepAllocation/multiStepAllocation';
import VendorManagment from './components/vendorList/VendorListMain';
import AddVendor from './components/vendor/VendorFormHeading';
import VendorMain from './routes/vendorForm/VendorMain';
import VendorDrawerContent from './components/projectUser/vendors/VendorDrawerContent';
import VendorAllocationSteps from './components/vendorBulkAllocation/multiStepVendorAllocation';
import DeliveredTaskUploadMain from './components/taskDelivered/deliveredTaskUploadMain';
import VendorUnallocationSteps from './components/vendorBulkUnallocation/multiStepVendorUnallocation';

const AppRoutes: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector((state: RootState) => state.company.company.data.email)
  const role = useSelector((state: RootState) => state.company.company.data.role)
  const dashboardAccess = useSelector((state: RootState) => state.company.company.data.modules.dashboard)
  const location = useLocation();

  useEffect(() => {
    const authToken = getCookie("token");
    if (!authToken) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const authToken = getCookie("token");
    if (authToken) {
      dispatch(getUserAccess({}));
    } 
  }, [getCookie("token")]);  


  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<HomePage />}>
        { dashboardAccess != null && dashboardAccess.access === true &&
          <Route path="/dashboard" element={<DashboardMain />} />
        }
        <Route path="adduser" element={<UserMain />} />
        <Route path="adduser/:id" element={<UserMain />} />
        <Route path="users" element={<UserListMain />} />
        <Route path="experts" element={<ExpertListMain />} />
        <Route path="addexpert" element={<ExpertMain />} />
        <Route path="addexpert/:id" element={<ExpertMain />} />
        <Route path="addproject" element={<ProjectMain />} />
        <Route path="addproject/:id" element={<ProjectMain />} />
        <Route path="projects" element={<ProjectListMain />} />
        <Route path="project/:id" element={<ProjectTab />} />
        <Route path="bulkupload" element={<BulkUploadTask />} />
        <Route path="addconfig/:id" element={<ConfigMain/>} />
        <Route path="editconfig/:id/:configId/:configRowId/" element={<ConfigMain/>} />
        <Route path="uploadtask/:id" element={<TaskUploadMain/>} />
        <Route path="/task/:id/:taskId" element={<TaskCompletedMain />} />
        <Route path="/allocation/:id/:option" element={<CustomizedSteppers />} />
        <Route path="/vendorbulkallocation/:id" element={<VendorAllocationSteps />} />
        <Route path="/vendorbulkunallocation/:id" element={<VendorUnallocationSteps />} />
        <Route path="/deliveredTask/:id" element={<DeliveredTaskUploadMain />} />
        <Route path="vendors" element={<VendorManagment />} />
        <Route path='/addvendor' element={<VendorMain />} />
        <Route path='addvendor/:id' element={<AddVendor />} />
      </Route>
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
