import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { MetaQCState } from '../interfaces/interface';
// import { createMetaQC } from '../slices/taskCompleted/metaQC';

const ExtraReducers = (builder: ActionReducerMapBuilder<MetaQCState>) => {
  // builder
  // .addCase(createMetaQC.pending, (state, action:any) => {
  //   state.createMetaQCStatus = STATUS.PENDING;
  //   state.createMetaQCMessage = null
  // })
  // .addCase(createMetaQC.fulfilled, (state, action) => {
  //   state.createMetaQCStatus = STATUS.SUCESS;
  //   state.metaqc = action.payload;
  //   state.createMetaQCMessage = null
  // })
  // .addCase(createMetaQC.rejected, (state, action:any) => {
  //   state.createMetaQCStatus = STATUS.FAILED;
  //   state.createMetaQCMessage = action.payload?.message || "An error occurred"
  // });
};

export default ExtraReducers;
