import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { GetPayload, VendorListState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/VendorListExtraReducer';

const initialState: VendorListState = {
  vendor: {
    count: undefined,
    results: []
  },
  analyse: [],
  permissions: {},
  error: null,
  getVendorStatus: STATUS.NOT_STARTED,
  createVendorStatus: STATUS.NOT_STARTED,
  createAnalyseStatus: STATUS.NOT_STARTED,
  unallocateBulkVendorStatus: STATUS.NOT_STARTED,
  unallocateAnalyseStatus: STATUS.NOT_STARTED,
  getVendorListMessage: "",
  createBulkVendorMessage: "",
  createAnalyseBulkVendorMessage: "",
  unallocateBulkVendorMessage: "",
  unallocateAnalyseBulkVendorMessage:"",


};

export const getVendorList = createAsyncThunk(
  'project/onboarding/vendor/get',
  async (payload: GetPayload = {}, thunkAPI) => {
    const { search, project_id, paginate } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(project_id && { project_id }),
      ...(paginate && { paginate: paginate.toString() }),
    }).toString();
    try {
      const response = await api.get(`project/onboarding/vendor?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }  
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.res?.data || "Failed to fetch vendor list")
    }
    
  }
);

export const createBulkVendor = createAsyncThunk(
  '/task/assign/vendor/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('/task/assign/vendor/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
      console.log(error)
     return thunkAPI.rejectWithValue(error.response?.data.message || "Failed to create vendors") 
    }
  }
);

export const createAnalyseBulkVendor = createAsyncThunk(
  'task/assign/analyse/vendor/POST',
  async (payload: any, thunkAPI) => {
    const { payload: payload_updated } = payload
    try {
      const response = await api.post('task/assign/analyse/vendor/', payload_updated);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
     return thunkAPI.rejectWithValue(error.res?.data || "Failed to create bulk vendor analysis")
    }
  }
);

export const unallocateBulkVendor = createAsyncThunk(
  'task/unallocate/bulk/vendor/',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.patch('task/unassign/vendor/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error: any) {
     return thunkAPI.rejectWithValue(error.res?.data || "Failed to unallocate bulk vendor") 
    }
  }
);

export const unallocateAnalyseBulkVendor = createAsyncThunk(
  'task/unallocate/bulk/vendor',
  async (payload: any, thunkAPI) => {
    const { payload: payload_updated } = payload
    try {
      const response = await api.post('task/unassign/analyse/vendor/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
     return thunkAPI.rejectWithValue(error.res?.data || "Failed to unallocate Analyse bulk vendor")
    }
  }
);

const vendorListSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCreateVendorStatus(state, action) {
      state.createVendorStatus = action.payload
    },
    setCreateAnalyseStatus(state, action) {
      state.createAnalyseStatus = action.payload
    },
    setCreateUnallocateVendorStatus(state, action) {
      state.unallocateBulkVendorStatus = action.payload
    },
    setCreateUnallocateAnalyseStatus(state, action) {
      state.unallocateAnalyseStatus = action.payload
    },
  },
  extraReducers
});

export const { setCreateVendorStatus, setCreateAnalyseStatus, setCreateUnallocateAnalyseStatus, setCreateUnallocateVendorStatus } = vendorListSlice.actions
export default vendorListSlice.reducer;
