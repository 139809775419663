import React, { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { getUser, setUpdateUsersStatus, updateUser } from '../../redux/slices/userSlice/addUserSlice';
import { CircularProgress, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../button/button';
import DeleteModal from '../modal/deleteModal';
import DisableModal from './disableModal';
import UserPagination from './userPagination';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { useStyles } from '../../assets/styles/listTableStyle';
import { SearchBar } from '../inputField/textfield';
import PaginationEntries from '../pagination/paginationEntries';
import TableRowItem from './userTableRowItem';
import TableHeader from '../table/tableHeader';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Error from '../error/error';
import debounce from 'lodash.debounce';

const UserListTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('first_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [userIdToDelete, setUserIdToDelete] = useState(""); 
  const [showDisableModal, setShowDisableModal] = useState(false); 
  const [userToDisable, setUserToDisable] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [usersData, setUsersData] = useState({})
  const [pagesSearchByInput, setPagesSearchByInput] = useState<any>()

  const users = useSelector((state: RootState) => state.users.users);
  const getStatus = useSelector((state: RootState) => state.users.getStatus);
  const updateStatus = useSelector((state: RootState) => state.users.updateStatus);
  const updateUserMessage = useSelector((state: RootState) => state.users.updateMessage);
  const adminTableAccess = useSelector((state: RootState) => state.company?.company?.data?.modules?.admin?.table?.access);
  const searchAccess = useSelector((state: RootState) => state.company?.company?.data?.modules?.admin?.table?.search?.access);
  const addAccess = useSelector((state: RootState) => state.company?.company?.data?.actions?.create);
  const tableFields = useSelector((state: RootState) => {
    const tableFields = state.company?.company?.data?.modules?.admin?.table?.table_fields;
    return tableFields || [];
  });
  const createVendorStatus = useSelector((state: RootState) => state.vendors.createVendorStatus)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.trim());
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false); 
  };

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };
  
  const confirmDelete = async () => {
    dispatch(updateUser({ id: userIdToDelete, is_deleted: true }));
  };

  const handleDisableCancel = () => {
    setShowDisableModal(false);
    setUserToDisable(null); 
  };  

  const handleDisableConfirm = () => {
    // if(updateStatus == STATUS.SUCESS) {
    //    setShowDisableModal(false);
    // }
    
    if (userToDisable) {
      const updatedStatus = !userToDisable.is_disabled;
      try {
        dispatch(updateUser({ id: userToDisable.id, is_disabled: updatedStatus }));
        dispatch(
          getUser({})
        );
        setShowDeleteModal(false);
        handleSnackbar('Success', 'success');
      } catch (error) {
        console.error('Failed to update user status', error);
      }
    }
    setUserToDisable(null);
  };


  useEffect(() => {
    if (updateStatus === STATUS.SUCESS) {
      setShowDeleteModal(false)
      dispatch(getUser({}));
      handleSnackbar(updateUserMessage, 'success');
  
    }
    if (updateStatus === STATUS.FAILED) {
      setShowDeleteModal(false)
      dispatch(getUser({}));
      handleSnackbar(updateUserMessage, 'error');
    } 
    return () => {
      if(updateStatus === STATUS.SUCESS || updateStatus === STATUS.FAILED){
        dispatch(setUpdateUsersStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  useEffect(() => {

    dispatch(getUser({ search: searchTerm, page: currentPage, order: sortOrder, pageSize: itemsPerPage }))
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  const handleAddClick = () => {
    navigate('/adduser')
  }

  const handleEditClick = (row: any) => {
      navigate(`/adduser/${row.id}`, { state: { userDetails: row, id: row.id } });
  };

  const handleToggleDisable = (row: any) => {
    setUserToDisable(row);
    setShowDisableModal(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setItemsPerPage(value as number);
    setCurrentPage(1);
    dispatch(getUser({ search: searchTerm, page: 1, pageSize: value as number })); 
  };
  const handleOnChange = (value: number) => {
    setItemsPerPage(value)
  }

  const totalPages = Math.ceil((users.count || 0) / itemsPerPage);

  const capitalizeAll = (str: string) => {
    return str.replace(/_/g, ' ') 
              .split(' ') 
              .map(word => word.toUpperCase()) 
              .join(' '); 
  };


useEffect(()=>{
  if (updateStatus == STATUS.SUCESS) {
    setShowDeleteModal(false);
  }
},[updateStatus])

  useEffect(() => { 
    if (updateStatus == STATUS.SUCESS) {
      setShowDisableModal(false)
    }
  }, [updateStatus])
  
  return (
    <>
      {adminTableAccess && (
        <div className={classes.container}>
          { searchAccess && ( <SearchBar onSearchChange={debouncedResults} /> )}
          <div className={classes.actionsContainer}>
            <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
            {addAccess && (
            <AddButton onClick={handleAddClick}>
              <PersonAddAlt1Icon fontSize='small' sx={{ marginRight: '5px' }} />Add User
            </AddButton>
            )}
          </div>
        </div>
      )}
      <TableContainer style={{ minHeight: '75px' }}>
        {getStatus === STATUS.PENDING && (
          <div className={classes.progressDiv}>
            <CircularProgress />
          </div>
        )}
        {getStatus === STATUS.SUCESS && (
          <Table className={classes.tableContainer} aria-label="simple table">
            <TableHeader headers={tableFields.map(capitalizeAll)} />
            <TableBody>
              {users && users.results && users.results.length > 0 ? (
                users.results.map((row: any) => (
                  <>
                  
                  <TableRowItem
                    key={row.id}
                    row={row}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    onToggleDisable={handleToggleDisable}
                  />
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className={classes.noUsersMessage}>
                    <Error message={'There are no users added as of yet. You can add a user to see it here.'} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {adminTableAccess && (
        <>
          <UserPagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
            itemsPerPage={itemsPerPage}
          />
          <DisableModal
            open={showDisableModal}
            onCancel={handleDisableCancel}
            onConfirm={handleDisableConfirm}
            isDisabled={userToDisable ? userToDisable.is_disabled : false}
          />
          <DeleteModal
            open={showDeleteModal}
            onCancel={cancelDelete}
            onConfirm={confirmDelete}
          />
          <SnackbarNotification
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleSnackbarClose}
          />
        </>
      )}
      </>
    );
}

export default UserListTable;

