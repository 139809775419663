import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { ConfigPayload, ConfigState, UploadPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/configExtraReducer';

const initialState: ConfigState = {
  config: {
    count: undefined,
    results: [],
    data: []
  },
  configData: {
    message: '',
    data: null,
    error: null
  },
  configCategories: [],
  currentConfig: [],
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  getConfigStatus: STATUS.NOT_STARTED,
  getConfigCategoryListStatus: STATUS.NOT_STARTED,
  getConfigDataStatus: STATUS.NOT_STARTED,
  createConfigMessage: "",
  updateConfigMessage: "",
  getConfigMessage: "",
  getConfigForTableMessage: "",
  getConfigCategoryListMessage: "",
  getConfigsDataMessage:""
};

export const createConfig = createAsyncThunk(
  'project-configs/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('project/company/config/', payload);
      const { status, data } = response;

      if (status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {     
        return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
)

export const updateConfig = createAsyncThunk(
  'project-configs/patch',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.patch('project/company/config/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${JSON.stringify(data.message)})`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
    }
)

export const getConfig = createAsyncThunk(
  'project-configs/get',
  async (payload: ConfigPayload = {}, thunkAPI) => {
    try {
      const { task_category, category } = payload;
      const queryString = new URLSearchParams({
        ...(task_category && { task_category }),
        ...(category && { category }),
    }).toString();
    const response = await api.get(`project-configs/collection?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

export const getConfigForTable = createAsyncThunk(
  'project/company/configs/get',
  async (payload: UploadPayload = { project_id: ''}, thunkAPI) => {
    try {
      const { search, page, pageSize, sort, order, project_id, id } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(id && { id }),
      ...(project_id && { project_id }),
    }).toString();
    const response = await api.get(`project/company/config/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

export const getConfigCategoryList = createAsyncThunk(
  'project/company/get_task_columns/get',
  async (payload: UploadPayload = { project_id: '' }, thunkAPI) => {
    try {
      const { project_id } = payload;
    const queryString = new URLSearchParams({
      ...(project_id && { project_id }),
    }).toString();
    const response = await api.get(`/project/company/get_task_columns?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
    } catch (error:any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);


export const getConfigsData = createAsyncThunk(
  'task/company/export/get',
  async (payload: UploadPayload = { project_id: '' }, thunkAPI) => {
    try {
      const { page, pageSize, project_id, type } = payload;

      const queryString = new URLSearchParams({
        ...(project_id && { project_id: String(project_id) }),
      }).toString();

      const response = await api.get(`task/company/data-dump/?${queryString}`);

      const { status, data } = response;

      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }

    } catch (error:any) {
      const truncatedMessage = `${error.response.data.message.slice(0, 100)}...`;
      return thunkAPI.rejectWithValue({ message: truncatedMessage })
    }
    }
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfigData(state, action: PayloadAction<any>) {
      state.config = { ...state.config, ...action.payload };
    },
    getConfigData(state, action) {
      state.currentConfig = action.payload;
    },
    setCreateConfigStatus(state, action) {
      state.createStatus = action.payload
    },
    setUpdateConfigStatus(state, action) {
      state.updateStatus = action.payload
    },
    setGetConfigDataStatus(state, action) {
      state.getConfigDataStatus = action.payload
    }
  },
  extraReducers
});

export const { updateConfigData, setCreateConfigStatus, getConfigData, setUpdateConfigStatus, setGetConfigDataStatus } = configSlice.actions;
export default configSlice.reducer;
