import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsExpertState, AnalyticsPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/analyticsExpertExtraReducer';

const initialState: AnalyticsExpertState = {
  analyticsExpert: {
    count: undefined,
    results: []
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
  downloadAnalyticsByExpertStatus: STATUS.NOT_STARTED,
  analyticsExpertDownload: '',
  getExpertAnalyticsMessage: "",
  getExpertAnalyticsDownloadMessage: ""
};

export const getExpertAnalytics = createAsyncThunk(
  '/task/company/analytics/by_expert/get',
  async (payload: AnalyticsPayload = { association_type: '' }, thunkAPI) => {
    try {
      const { search, page, pageSize, sort_by, sort_order, project_id, type, association_type, download } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort_by && { sort_by }),
        ...(sort_order && { sort_order }),
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(association_type && { association_type }),
        ...(download != undefined && { download: download.toString() }),
      }).toString();
      const response = await api.get(`/task/company/analytics/by_expert?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

export const getExpertAnalyticsDownload = createAsyncThunk(
  '/task/company/analytics/by_expert/get/download',
  async (payload: AnalyticsPayload = { association_type: '' }, thunkAPI) => {
    try {
      const { search, page, pageSize, sort_by, sort_order, project_id, type, association_type, download } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort_by && { sort_by }),
        ...(sort_order && { sort_order }),
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(association_type && { association_type }),
        ...(download != undefined && { download: download.toString() }),
      }).toString();
      console.log(type)
      const response = await api.get(`/task/company/analytics/by_expert?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

const analyticsExpertsSlice = createSlice({
  name: 'analyticsExpert',
  initialState,
  reducers: {
    setDownloadAnalyticsByExpertStatus: (state, actions) => {
      state.downloadAnalyticsByExpertStatus = actions.payload
    }
  },
  extraReducers
});

export const { setDownloadAnalyticsByExpertStatus } = analyticsExpertsSlice.actions
export default analyticsExpertsSlice.reducer;
