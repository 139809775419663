import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { CompanyState } from '../interfaces/interface';
import { getUserAccess } from '../slices/company';

const ExtraReducers = (builder: ActionReducerMapBuilder<CompanyState>) => {
  builder
  .addCase(getUserAccess.pending, (state) => {
    state.getStatus = STATUS.PENDING;
    state.getUserAccessMessage = null
  })
  .addCase(getUserAccess.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.company = action.payload;
    state.getUserAccessMessage = null
  })
  .addCase(getUserAccess.rejected, (state, action:any) => {
    state.getStatus = STATUS.FAILED;
    state.getUserAccessMessage = action.payload?.message || "An error occurred"
  });
};

export default ExtraReducers;
