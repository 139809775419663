import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createUser, getUser, updateUser} from '../slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import { UserTypeState } from '../interfaces/interface';

const ExtraReducers = (builder: ActionReducerMapBuilder<UserTypeState>) => {
  builder
    .addCase(createUser.pending, (state) => {
      state.createStatus = STATUS.PENDING;
      state.createUserMessage = null
    })
    .addCase(createUser.fulfilled, (state, action: PayloadAction<any>) => {
      console.log('Create Fulfilled:', action.payload); 
      state.users = action.payload; 
      state.createStatus = STATUS.SUCESS; 
      state.createUserMessage = action.payload?.message || "Success"
      console.log(action.payload)
    })
    .addCase(createUser.rejected, (state, action:any) => { 
      state.createStatus = STATUS.FAILED;
      state.createUserMessage = action.payload?.message || "Phone number already exist"
    })
    .addCase(getUser.pending, (state, action:any) => {
      state.getStatus = STATUS.PENDING;
      state.getUserMessage = null
    })
    .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.users = action.payload; 
      state.getStatus = STATUS.SUCESS;
      state.getUserMessage = action.payload?.message || "Success"
    })
    .addCase(getUser.rejected, (state, action:any) => { 
      state.getStatus = STATUS.FAILED;
      state.getUserMessage = action.payload?.message || "An error occurred"
    })
    .addCase(updateUser.pending, (state, action) => {
      state.updateStatus = STATUS.PENDING;
      state.updateMessage = null
    })
    .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
      const updatedUser = action.payload;
      state.users.results = state.users.results.map((user: any) =>
        user.id === updatedUser.id ? updatedUser : user
      );
      state.updateStatus = STATUS.SUCESS;
      state.updateMessage = action.payload?.message || "Success"
      console.log(action.payload)
    })
    .addCase(updateUser.rejected, (state, action:any) => {
      state.updateStatus = STATUS.FAILED;
      state.updateMessage = action.payload?.message || "An error occurred"

    })
};

export default ExtraReducers;
