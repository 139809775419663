import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createUser, getUser, updateUser} from '../slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import { ConfigState } from '../interfaces/interface';
import { createConfig, getConfig, getConfigCategoryList, getConfigForTable, getConfigsData, updateConfig } from '../slices/config';

const ExtraReducers = (builder: ActionReducerMapBuilder<ConfigState>) => {
  builder
  .addCase(createConfig.pending, (state) => {
    state.createStatus = STATUS.PENDING;
    state.createConfigMessage = null
  })
  .addCase(createConfig.fulfilled, (state, action: PayloadAction<any>) => { 
    state.config = action.payload; 
    state.createStatus = STATUS.SUCESS; 
    state.createConfigMessage = action.payload?.message || "Success"
  })
  .addCase(createConfig.rejected, (state, action:any) => { 
    state.createStatus = STATUS.FAILED;    
    state.createConfigMessage = action.payload?.message || "An error occurred"
  })

  .addCase(getConfig.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.config = action.payload;
    state.getConfigMessage = "Success"  
  })
  .addCase(getConfig.pending, (state, action) => {
    state.getStatus = STATUS.PENDING;
    state.getConfigMessage = null
  })
  .addCase(getConfig.rejected, (state, action:any) => {
    state.getStatus = STATUS.FAILED;
    state.getConfigMessage = action.payload?.message || "An error occurred"
  })
  .addCase(getConfigForTable.pending, (state, action) => {
    state.getConfigStatus = STATUS.PENDING;
    state.getConfigForTableMessage = null
  })
  .addCase(getConfigForTable.fulfilled, (state, action) => {
    state.getConfigStatus = STATUS.SUCESS;
    if(Object.keys(action.payload).includes('results')) {
      state.config = action.payload;
    } else {
      state.currentConfig = action.payload
    }
    state.getConfigForTableMessage = null
  })
  .addCase(getConfigForTable.rejected, (state, action:any) => { 
    state.getStatus = STATUS.FAILED;
    state.getConfigForTableMessage = action.payload?.message || "An error occurrred"
  })
  .addCase(getConfigCategoryList.pending, (state, action) => {
    state.getConfigCategoryListStatus = STATUS.PENDING;
    state.getConfigCategoryListMessage = null
  })
  .addCase(getConfigCategoryList.fulfilled, (state, action) => {
    state.getConfigCategoryListStatus = STATUS.SUCESS;
    state.configCategories = action.payload;
    state.getConfigCategoryListMessage = null
  })
  .addCase(getConfigCategoryList.rejected, (state, action:any) => { 
    state.getConfigCategoryListStatus = STATUS.FAILED;
    state.getConfigCategoryListMessage = action.payload?.message || "An error occurred"
  })
  .addCase(updateConfig.pending, (state) => {
    state.updateStatus = STATUS.PENDING;
    state.updateConfigMessage = null
  })
  .addCase(updateConfig.fulfilled, (state, action: PayloadAction<any>) => { 
    state.config = action.payload; 
    state.updateStatus = STATUS.SUCESS;
    state.updateConfigMessage = action.payload?.message || "Success"
  })
  .addCase(updateConfig.rejected, (state, action: any) => { 
    state.updateStatus = STATUS.FAILED;
    state.updateConfigMessage = action.payload?.message || "An error occurred"
  })
  .addCase(getConfigsData.pending, (state) => {
    state.getConfigDataStatus = STATUS.PENDING
    state.getConfigsDataMessage = null
  })
  .addCase(getConfigsData.fulfilled,(state, action:any)=> {
    state.configData = action.payload
    state.getConfigDataStatus = STATUS.SUCESS
    state.getConfigsDataMessage = null
  })
  .addCase(getConfigsData.rejected, (state, action:any) => {
    state.getConfigDataStatus = STATUS.FAILED
    state.getConfigsDataMessage = action.payload?.message || "An error occurred"
  })
};

export default ExtraReducers;
