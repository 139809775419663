import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button, useStepContext } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useParams } from 'react-router-dom';
import { createAllocation } from '../../redux/slices/allocation';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { STATUS } from '../../utils/status';
import { setCreateAllocationStatus } from '../../redux/slices/upload';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const InfoModelTaskUnallocation = ({ open, setOpen, openTaskUnallocationModel, openReviewUnallocationModel, setOpenTaskUnallocationModel, setOpenReviewUnallocationModel }: any) => {
    // const selectedTaskIds = useSelector((state: RootState) => state.tasks.selectedIds);
    const [taskUnallocationId, setTaskUnallocationId]: any = useState([])
    const [reviewUnallocationId, setReviewUnallocationId]: any = useState([])
    const [expertId, setExpertId]: any = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');


    const selectedTasksWithoutAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithoutAllocation);
    const selectedReviewsWithoutAllocation = useSelector((state: RootState) => state.allocationTask.reviewWithoutAllocation);
    const selectedTasksWithAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithAllocation);
    const selectedReviewsWithAllocation = useSelector((state: RootState) => state.allocationTask.reviewWithAllocation);
    const upload = useSelector((state: RootState) => state.upload.upload);
    const createStatus = useSelector((state: RootState)=> state.allocation.createStatus);
    const createError = useSelector((state: RootState)=> state.allocation.createError);




    const dispatch = useDispatch<AppDispatch>()


    const taskCountAllocated = selectedTasksWithAllocation.length;
    const reviewCountAllocated = selectedReviewsWithAllocation.length

    const taskCountUnallocated = selectedTasksWithoutAllocation.length
    const reviewCountUnallocated = selectedReviewsWithoutAllocation.length

    const handleClose = () => {
        setOpen(false)
        setOpenTaskUnallocationModel(false)
        setOpenReviewUnallocationModel(false)
    }

    const { id } = useParams()


    useEffect(() => {
        for (let i = 0; i < selectedReviewsWithAllocation.length; i++) {
            // console.log(selectedReviewsWithAllocation)
            setReviewUnallocationId([...reviewUnallocationId, selectedReviewsWithAllocation[i].id])
        }
        for (let i = 0; i < selectedTasksWithAllocation.length; i++) {
            setTaskUnallocationId([...taskUnallocationId, selectedTasksWithAllocation[i].id])
        }
        for (let i = 0; i < upload?.results?.length; i++) {
            if (selectedTasksWithAllocation.map(task => task.id).includes(upload.results[i].id)) {
                setExpertId([...expertId, ...upload.results[i].task_allocations.map((exp: any) => exp.id)])
            }
            // console.log(expertId.filter((expertId:any)=> taskUnallocationId === expertId))
        }
    }, [selectedReviewsWithAllocation, selectedTasksWithAllocation])



    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleUnallocateTaskerButton = () => {
        const payload = {
            action: "unassign",
            type: "TASK",
            task_ids: Array.from(new Set(selectedTasksWithAllocation.map((task) => task.id))),
            expert_ids: Array.from(new Set(expertId)),
            project_id: id,
        };
        if (selectedReviewsWithAllocation.length > 0) {
                dispatch(createAllocation(payload));
                handleSnackbar("Success", "success");
        setOpen(false);
        }
    };

    const handleUnallocateReviewerButton = () => {
        const payload = {
            action: "unassign",
            type: "REVIEW",
            expert_ids: Array.from(new Set(expertId)),
            project_id: id,
            task_ids: Array.from(
                new Set(selectedReviewsWithAllocation.map((task) => task.id))
            ),
        };
            dispatch(createAllocation(payload));
            setOpen(false);

    };

    useEffect(() => {
        if(createStatus === STATUS.SUCESS){
            // handleSnackbar("Success", "success")
            handleSnackbar(createError, "success")
        }
        if(createStatus === STATUS.FAILED){
            // handleSnackbar("Failed", "error")
            handleSnackbar(createError, "error")
        }
        if(createStatus === STATUS.PENDING){}

        return () => {
            if(createStatus === STATUS.SUCESS || createStatus === STATUS.FAILED){
                dispatch(setCreateAllocationStatus(STATUS.NOT_STARTED))
            }
        }
    },[createStatus])


    // console.log("Number of task already Allocated user and you want to unallocate them", selectedTasksWithAllocation)
    // console.log("Number of task having already Allocated Reviews and you want to unallocate them" , selectedReviewsWithAllocation)


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Unallocate
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {openTaskUnallocationModel === true ? (
                    <DialogContent>
                        <Typography gutterBottom>
                            {/* You selected {taskCountAllocated} task{taskCountAllocated !== 1 ? 's' : ''} that {taskCountAllocated !== 1 ? 'are' : 'is'} allocated and now you want to unallocate tasks. */}
                            <b> You have selected {taskCountAllocated} tasks to unallocate, with {taskCountUnallocated} already unallocated. Currently, a task can only be allocated to a single tasker.
                                Would you like to proceed with Unallocating the remaining {taskCountAllocated} allocated tasks?</b> Click "Unallocate" to unallocate.
                        </Typography>
                        <Typography gutterBottom>
                        </Typography>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Typography gutterBottom>
                            {/* You selected {reviewCountAllocated} Review{reviewCountAllocated !== 1 ? 's' : ''} that {reviewCountAllocated !== 1 ? 'are' : 'is'} allocated and now you want to unallocate tasks. */}
                            <b> You have selected {reviewCountAllocated} tasks to unallocate, with {reviewCountUnallocated} already unallocated. Currently, a task can only be allocated to a single tasker.
                                Would you like to proceed with Unallocating the remaining {reviewCountAllocated} allocated tasks?</b> Click "Unallocate" to unallocate.
                        </Typography>
                        <Typography gutterBottom>
                        </Typography>
                    </DialogContent>
                )}
                <DialogActions>
                    {openTaskUnallocationModel === true ? (<Button autoFocus variant="contained" onClick={handleUnallocateTaskerButton} style={{}}>

                        Unallocate taskers
                    </Button>
                    ) : (
                        <Button autoFocus variant="contained" onClick={() => handleUnallocateReviewerButton()} style={{}}>

                            Unallocate reviewers
                        </Button>)}
                </DialogActions>
            </BootstrapDialog>

            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    )
}


export default InfoModelTaskUnallocation;