import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { getConfig, setCreateConfigStatus, setUpdateConfigStatus, updateConfigData } from '../../redux/slices/config';
import BooleanTypeContent from './booleanTypeContent';
import DictTypeContent from './dictTypeContent';
import ListTypeContent from './listTypeContent';
import StringTypeContent from './stringTypeContent';
import ChipList from './chipList';
import JsonTypeContent from './jsonTypeContent';
import { Typography, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';

const Config = () => {
  const [selectedChip, setSelectedChip] = useState<string | null>(null);
  const [listFields] = useState<string[]>([]);
  const [clickedChip, setClickedChip] = useState<{ id: string; name: string, interactive_config: boolean } | null>(null);
  const [initialSelectionDone, setInitialSelectionDone] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const configData = useSelector((state: RootState) => state.config.config);
  const [inputValue, setInputValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [dictFields, setDictFields] = useState<{ [key: string]: string }>({});
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchedProject = useSelector((state: RootState) => state.projects.project);
  const task_category_name = fetchedProject.task_category_name && fetchedProject.task_category_name;
  const task_category = fetchedProject.task_category_id && fetchedProject.task_category_id;
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const getConfigStatus = useSelector((state: RootState) => state.config.getStatus)
  const getConfigCategpryListStatus = useSelector((state: RootState) => state.config.getConfigCategoryListStatus)
  const path = window.location.href.includes("addconfig")
  let { configRowId } = useParams()
  const createConfigMessage = useSelector((state: RootState) => state.config.createConfigMessage)
  const updateConfigMessage = useSelector((state: RootState) => state.config.updateConfigMessage)

  const pathh = window.location.pathname;
  const segment = pathh.split("/")
  const configId = segment[4]

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(getProject({ project_id: id, id: configRowId }));
    }
  }, [id, dispatch]);

  const updateConfigInStore = (updatedConfigData: any) => {
    dispatch(updateConfigData(updatedConfigData));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    updateConfigInStore({ inputValue: event.target.value });
  };


  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {(task_category!=='') &&
    dispatch(getConfig({ id, task_category, category: 'TASK' }));
    }
  }, [dispatch, id, task_category]);

  useEffect(() => {
    if (createStatus === STATUS.SUCESS) {
      navigate(`/project/${id}?tab=config`);
      handleSnackbar(createConfigMessage, 'success');
    } else if (createStatus === STATUS.FAILED) {
      handleSnackbar(createConfigMessage, 'warning');
    }
    return () => {
      if (createStatus === STATUS.SUCESS || createStatus === STATUS.FAILED) {
        dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  useEffect(() => {
    if (updateStatus === STATUS.SUCESS) {
      navigate(`/project/${id}?tab=config`);
      handleSnackbar(updateConfigMessage, 'success');
    } else if (updateStatus === STATUS.FAILED) {
      handleSnackbar(updateConfigMessage, 'error');
    }
    return () => {
      if (updateStatus === STATUS.SUCESS || updateStatus === STATUS.FAILED) {
        dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus]);

  useEffect(() => {
    if (!initialSelectionDone && configData.data && configData.data.length > 0 && path) {
      const firstChip = configData.data[0];
      setSelectedChip(firstChip && firstChip.value);
      setClickedChip({ id: firstChip.id, name: firstChip && firstChip.value, interactive_config: firstChip.interactive_config });
      setInitialSelectionDone(true);
    }
  }, [configData, initialSelectionDone, path]);

  const handleChipClick = (chipLabel: string, chipId: string, chipInteractive: boolean) => {
    setSelectedChip(chipLabel);
    setClickedChip({ id: chipId, name: chipLabel, interactive_config: chipInteractive });
  };

  const handleInputChange = (key: string, value: string) => {
    setDictFields(prevDictFields => ({
      ...prevDictFields,
      [key]: value
    }));
  };

  const handleSubmitJson = (jsonData: Array<{ [key: string]: string }>) => {
    updateConfigInStore({ jsonData });
  };

  const renderChipContent = (chipLabel: string, chipId: string,) => {
    if (!configData.data) return null;
    if (!Array.isArray(configData.data)) return null;
    const selectedConfig = configData.data?.find((config) => config.value === chipLabel);

    if (!selectedConfig) {
      return null;
    }

    const { type, keys } = selectedConfig;
    switch (type) {
      case 'list':
        return (
          <ListTypeContent
            chipId={chipId || ''}
            chipLabel={chipLabel}
            listFields={listFields}
            keys={keys || null}
            taskCategory={task_category}
          />
        );

      case 'boolean':
        return (
          <BooleanTypeContent
            chipId={chipId || ''}
            chipLabel={chipLabel}
            isChecked={isChecked}
            setIsChecked={setIsChecked || false}
            taskCategory={task_category}
          />
        );

      case 'dict':
        return (
          <DictTypeContent
            chipId={chipId || ''}
            chipLabel={chipLabel}
            keys={keys || null}
            handleInputChange={handleInputChange}
            taskCategory={task_category}
            interactiveConfig={clickedChip?.interactive_config || false}
          />
        );

      case 'string':
        return (
          <StringTypeContent
            chipId={chipId || ''}
            chipLabel={chipLabel}
            inputValue={inputValue}
            handleChange={handleChange}
            taskCategory={task_category}
          />
        );

      case 'json':
        return (
          <JsonTypeContent
            chipId={chipId || ''}
            chipLabel={chipLabel}
            keys={keys || []}
            handleSubmit={handleSubmitJson}
            taskCategory={task_category}
          />
        );

      default:
        return null;
    }

  };

  return (
    <>
      <Typography style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>
        Select Config Type for {task_category_name}
      </Typography>
      {(getConfigStatus === STATUS.SUCESS || getConfigCategpryListStatus === STATUS.SUCESS) &&
        <div style={{ marginTop: '10px' }}>
          <div style={{ marginTop: '20px' }}></div>
          <ChipList configData={configData} handleChipClick={handleChipClick} selectedChip={selectedChip} />
          <div style={{ marginTop: '20px' }}></div>
          {selectedChip && clickedChip?.id && renderChipContent(selectedChip, clickedChip.id)}
        </div>
      }
      {getConfigStatus === STATUS.PENDING && <CircularProgress size={30} sx={{ marginTop: '20px' }} />}
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default Config;
