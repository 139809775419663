import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { useStyles } from '../../../assets/styles/listTableStyle';
import { RootState } from '../../../redux/store/appStore';
import { STATUS } from '../../../utils/status';
import { CircularProgress, TableHead } from '@mui/material';
import { TableCellBody, TableCellHead } from '../../table/tableCell';
// import TableRowItem from '../dataTableInformationRowItem';
import Error from '../../error/error';

interface Type1TaskStatusProps {
  allocationType: string;
}

const Type1TaskStatus: React.FC<Type1TaskStatusProps> = ({ allocationType }) => {
  const classes = useStyles();
  const analyseData = useSelector((state: RootState) => state.bulkAllocation.taskStatus)
  const createAnalyseStatus = useSelector((state: RootState) => state.bulkAllocation.createTaskAnalyseStatus);
  console.log({ analyseData, createAnalyseStatus })

  const headers = ["STATUS", "COUNT"]

  return (
    <>
      <TableContainer sx={{ backgroundColor: 'white', minHeight: '200px' }}>
        {createAnalyseStatus === STATUS.PENDING && (
          <div className={classes.progressDiv}>
            <CircularProgress />
          </div>
        )}
        {createAnalyseStatus === STATUS.SUCESS && (
          <>
            <Table className={classes.tableContainer} aria-label="simple table">
              <TableHead>
                {headers.map((header) => (
                  <TableCellHead key={header}>{header}</TableCellHead>
                ))}
              </TableHead>
              <TableBody>
                {analyseData && analyseData.data && Object.keys(analyseData.data).length > 0 ? (
                  Object.entries(analyseData.data).map(([status, count]: [string, any]) => (
                    <TableRow key={status}>
                      <TableCellBody>{status}</TableCellBody>
                      <TableCellBody>{count}</TableCellBody>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className={classes.noUsersMessage}>
                      <Error message={'There are no users added as of yet. You can add a user to see it here.'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </>
        )}
      </TableContainer>
    </>
  );
};

export default Type1TaskStatus;
