import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AllocationState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/allocationExtraReducer';

const initialState: AllocationState = {
  allocation: {
    count: undefined,
    results: []
  },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  createError: ""
};

export const createAllocation = createAsyncThunk(
  'task/company/allocate-tasks/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('task/company/allocate-tasks/', payload);
      const { status, data } = response;
      if (status === 200) {
        return { data, payload };
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
)

const allocationSlice = createSlice({
  name: 'allocation',
  initialState,
  reducers: {
    setCreateAllocationStatus(state, action) {
      state.createStatus = action.payload
    },
    setUpdateAllocationStatus(state, action) {
      state.updateStatus = action.payload
    }
  },
  extraReducers
});

export const { setCreateAllocationStatus, setUpdateAllocationStatus } = allocationSlice.actions
export default allocationSlice.reducer;
