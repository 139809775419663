import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import RadioAction from './radioAction';
import AudioAction from './audioAction';
import ContentAction from './contentAction';
import CheckboxAction from './checkboxAction';
import LabelAction from './labelAction';
import EditAction from './editAction';
import RatingAction from './ratingAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import VideoShowConfig from './videoShowAction';
import VideoShowAction from './videoShowAction';
import AudioShowAction from './audioShowAction';
import PdfShowAction from './pdfShowAction';
import ParagraphShowAction from './paragraphShowAction';
import ImageShowAction from './imageShowAction';
import SingleSelectAction from './singleSelectAction';
import MultiSelectAction from './multiSelectAction';
import CodeAction from './codeTypeAction';
import AdjectiveList from './adjectiveList';

interface ActionConfigDetails {
    id: number;
    name: string;
    value: string;
    type: string;
    description: string;
    allow_multiple: boolean;
    keys: string[] | null;
    created_at: string;
    updated_at: string;
}

interface ActionValue {
    order: number;
    config_details: {
        name: string;
        value: {
            label?: string;
            value?: { index: number; value: string }[];
            title?: string;
            description?: string;
        };
        type: string;
        order: number;
        config_details: ActionConfigDetails;
    };
    id: string;
    content?: string;
    url?: string;
    selected?: { index: number; value: string }[];
}

interface ResponseActionProps {
    action?: {
        action: string;
        action_value: ActionValue[];
    };
    actionIndex?: number;
    result?: any;
    taskStatus: string;
    metaQCStatus: string;
}

const ResponseAction: React.FC<ResponseActionProps> = ({ taskStatus, metaQCStatus, action, actionIndex, result }) => {
    const projectStatus = useSelector((state: RootState)=> state.projects.project.status)
    if (!action) {
        return (
            <Card variant="outlined" sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography variant="body1" color   ="textSecondary">
                        No action available 
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    // const sortedActions = [...action.action_value].sort((a, b) => 
    //     a.config_details.order - b.config_details.order
    // );

    const renderActionContent = (action: any) => {
        switch (action.action) {
            case 'audio_recording':
                return <AudioAction actionValue={action.action_value}/>
            case 'content':
                return <ContentAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'radio':
                return <RadioAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} taskStatus={taskStatus} metaQCStatus={metaQCStatus} />;
            case 'single_select':
                return <SingleSelectAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'multi_select':
                return <MultiSelectAction heading={"Multi Select"} actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'checkbox':
                return <CheckboxAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'video':
                return <VideoShowAction action_value={action.action_value}/>;
            case 'audio':
                return <AudioShowAction action_value={action.action_value} />;
            case 'pdf':
                return <PdfShowAction action_value={action.action_value} />;
            case 'paragraph':
                return <ParagraphShowAction action_value={action.action_value} result={result}/>;
            case 'image':
                return <ImageShowAction action_value={action.action_value} />;
            case 'edited':
                return <EditAction actionValue={action.action_value} />;
            case 'labelled':
                return <LabelAction actionValue={action.action_value} />;
            case 'rate':
                return <RatingAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'code':
                return <CodeAction actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            case 'adjectives_list':
                return <AdjectiveList heading={"Adjective List"} actionValue={action.action_value} action={action.action} actionIndex={action.id} result={result} projectStatus={projectStatus} />;
            default:
                return null;
        }
    };
    return (
        <div style={{ marginBottom: 3 }}>
            {action.action_value.map((actionValue) => {
                return (
                    <div key={actionValue.id} style={{ marginBottom: "1.4%" }}>
                        {renderActionContent({ ...action, action_value: [actionValue] })}
                    </div>
                )
            })}
        </div>
    );
}

export default ResponseAction;
