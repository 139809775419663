import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, SelectChangeEvent, InputAdornment, TextField, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { setUpdateUsersStatus } from '../../redux/slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { UserRoleSelect } from '../inputField/selectField';
import useStyles from '../../assets/styles/style';
import { AddButton } from '../button/button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getProject, setUpdateProjectStatus, updateProject } from '../../redux/slices/projectSlice/addProjectSlice';

interface FormState {
  id?: string;
  task_amount: number;
  payment_type: string;
  review_amount: number;
}

const PaymentForm = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { paymentDetails: initialUserDetails, id: userId } = location.state || { paymentDetails: null, id: '' };
  const [paymentDetails, setPaymentDetails] = useState<FormState>({
    task_amount: 0,
    payment_type: '',
    review_amount: 0,
  });
  const updateStatus = useSelector((state: RootState) => state.projects.updateStatus);
  const updateProjectMessage = useSelector((state: RootState) => state.projects.updateProjectMessage)
  const createProjectMessage = useSelector((state: RootState) => state.projects.createProjectMessage)
  const { id } = useParams<string>();
  const fetchedProject = useSelector((state: RootState) => state.projects.projects);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [endAdornment, setEndAdornment] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (fetchedProject) {
      console.log({fetchedProject})
      setPaymentDetails({
        id: fetchedProject.id || '',
        task_amount: Number(fetchedProject.task_amount) || 0,
        review_amount: Number(fetchedProject.review_amount) || 0,
        payment_type: fetchedProject.payment_type || '',
      });
      if (fetchedProject.payment_type === 'TIME') {
        setEndAdornment('per hour');
      } else {
        setEndAdornment('');
      }
    }
  }, [fetchedProject]);

  useEffect(() => {
    if (initialUserDetails) {
      setPaymentDetails({
        ...initialUserDetails,
        task_amount: Number(initialUserDetails.task_amount) ,
        review_amount: Number(initialUserDetails.review_amount),
      });
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialUserDetails]);

  useEffect(() => {
    const isValid =
      paymentDetails.task_amount >= 0 &&
      paymentDetails.review_amount >= 0 &&
      paymentDetails.payment_type.trim() !== '';
    setIsFormValid(isValid);
  }, [paymentDetails]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = value === '' ? '' : Number(value);
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: numericValue,
    }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
    if (value === 'TIME') {
      setEndAdornment('per hour');
    } else {
      setEndAdornment('');
    }
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};

    if (isNaN(paymentDetails.task_amount) || paymentDetails.task_amount < 0) {
      errors.task_amount = 'Valid Task Amount is Required';
    } else if (paymentDetails.task_amount === 0) {
      delete errors.task_amount; 
    }
    
    if (isNaN(paymentDetails.review_amount) || paymentDetails.review_amount < 0) {
      errors.review_amount = 'Valid Review Amount is Required';
    } else if (paymentDetails.review_amount === 0) {
      delete errors.review_amount; 
    }
    
    if (!paymentDetails.payment_type.trim()) {
      errors.payment_type = 'Payment Type is Required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }

    const payload = {
      id: id,
      task_amount: paymentDetails.task_amount,
      payment_type: paymentDetails.payment_type,
      review_amount: paymentDetails.review_amount,
    };
    dispatch(updateProject(payload));
  };
  useEffect(() => {
    if (updateStatus === STATUS.SUCESS) {
      if (id) {
        const payload = { id }; 
        dispatch(getProject(payload)) 
          .unwrap() 
          .then((updatedProject) => {
            setPaymentDetails({
              id: updatedProject.id || '',
              task_amount: Number(updatedProject.task_amount) || 0,
              review_amount: Number(updatedProject.review_amount) || 0,
              payment_type: updatedProject.payment_type || '',
            });
            if (updatedProject.payment_type === 'TIME') {
              setEndAdornment('per hour');
            } else {
              setEndAdornment('');
            }
            handleSnackbar(updateProjectMessage, 'success');
          })
          .catch(() => {
            handleSnackbar(updateProjectMessage, 'error');
          });
      } else {
        handleSnackbar(updateProjectMessage, 'error');
      }
    } else if (updateStatus === STATUS.FAILED) {
      handleSnackbar(updateProjectMessage, 'error');
    }
  
    return () => {
      if(updateStatus === STATUS.SUCESS || updateStatus === STATUS.FAILED){
        dispatch(setUpdateProjectStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, dispatch, id]);
  
  

  return (
    <Box component="form" onSubmit={handleSubmit} className={classes.formContainer}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <UserRoleSelect
            label="Payment Type"
            value={paymentDetails.payment_type}
            onChange={(e) => handleSelectFieldChange(e, 'payment_type')}
            options={[
              { value: 'FIXED', label: 'Fixed' },
              { value: 'WORK', label: 'Task Basis' },
              { value: 'TIME', label: 'Time Basis' },
            ]}
            error={formSubmitted && formErrors.payment_type ? formErrors.payment_type : ''}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop:'20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '32%', marginRight:'20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }} >Task Amount</Typography>
          <TextField
            placeholder="Enter Task Amount"
            name="task_amount"
            type="number" 
            value={paymentDetails.task_amount}
            onChange={handleFieldChange}
            size='small'
            error={formSubmitted && Boolean(formErrors.task_amount)}
            helperText={formSubmitted ? formErrors.task_amount : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon/>
                </InputAdornment>
              ),
              endAdornment: endAdornment && (
                <InputAdornment position="end">
                  <Typography variant="body2" color="textSecondary" style={{backgroundColor: '#3D85C6',color: 'white',borderRadius: '4px',padding: '2px 8px',marginLeft: '10px',fontSize: '0.75rem'}}>
                  {endAdornment}
                </Typography>
              </InputAdornment>
              )
            }}
          />
          </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }} >Review Amount</Typography>
          <TextField
            placeholder="Enter Reviewer Amount"
            name="review_amount"
            type="number" 
            value={paymentDetails.review_amount}
            onChange={handleFieldChange}
            size='small'
            error={formSubmitted && Boolean(formErrors.review_amount)}
            helperText={formSubmitted ? formErrors.review_amount : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon/>
                </InputAdornment>
              ),
              endAdornment: endAdornment && (
                <InputAdornment position="end">
                <Typography variant="body2" color="textSecondary" style={{backgroundColor: '#3D85C6',color: 'white',borderRadius: '4px',padding: '2px 8px',marginLeft: '10px',fontSize: '0.75rem'}}>
                  {endAdornment}
                </Typography>
              </InputAdornment>
              )
            }}
          />
          </div>
        </div>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <AddButton
            type="submit"
            // disabled={!isFormValid}
            disabled={!isFormValid || updateStatus == STATUS.PENDING}
            startIcon={updateStatus == STATUS.PENDING? <CircularProgress size={17} color='success' /> : <></>} 
          >Update Payment Type</AddButton>
        </Box>
      </div>

      <SnackbarNotification
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default PaymentForm;
