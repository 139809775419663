import React from 'react';
import { Button, Box, CircularProgress, Dialog, DialogActions, Divider, Typography } from '@mui/material';
import { STATUS } from '../../utils/status';
import { RootState } from '../../redux/store/appStore';
import { useSelector } from 'react-redux';
import { AddButton } from '../button/button';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, onConfirm }) => {
  const createStatus = useSelector((state: RootState) => state.allocation.createStatus);
  const path = window.location.href.includes('allocation') && !window.location.href.includes('typeOfTask')
  return (
    <>
      {!path ? (
        <Dialog open={open} onClose={onClose}>
          <div style={{ padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#000000' }}>
                  Allocate?
                </Typography>
              </div>
            </div>
            <Divider style={{ marginBottom: '10px' }} />

            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#000000', marginTop: '30px' }} id="delete-modal-description">
              Are you sure you want to allocate selected tasks?
            </Typography>
            <DialogActions sx={{ marginTop: '20px' }}>
              <Button
                sx={{
                  minWidth: '70px',
                  minHeight: '40px',
                  backgroundColor: '#F7F9FA',
                  padding: '8px 16px',
                  gap: '4px',
                  ml: '60%',
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#E0E0E0' },
                }}
                onClick={onClose}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#10383D',
                    textTransform: 'none',
                  }}
                >
                  No
                </Typography>
              </Button>
              <Button
                sx={{
                  minWidth: '66px',
                  minHeight: '40px',
                  backgroundColor: '#3D85C6',
                  padding: '8px 16px',
                  gap: '4px',
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#3D85C6' },
                }}
                onClick={onConfirm}
                disabled={createStatus == STATUS.PENDING}
                startIcon={createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : <></>}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                >
                  Yes
                </Typography>
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      ) : (
        <Box
          sx={{
            backgroundColor: 'white',
            maxWidth: '600px',
            margin: 'auto',
            borderRadius: '12px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            marginTop: '40px'
          }}
        >
          <div
            style={{
              padding: '24px',
              borderRadius: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#333',
                }}
              >
                Allocate?
              </Typography>
            </div>
            <Divider style={{ marginBottom: '20px' }} />

            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '22px',
                color: '#333',
              }}
              id="delete-modal-description"
            >
              Are you sure you want to allocate the selected tasks?
            </Typography>

            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
            </div>
          </div>
        </Box>
      )
      }
    </>
  );
};

export default ConfirmationDialog;
