import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { GetPayload, UserTypeState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/userExtraReducers';

const initialState: UserTypeState = {
  users: {
    count: undefined,
    results: []
  },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  createUserMessage: "",
  getUserMessage: "",
  updateMessage: ""

};

export const createUser = createAsyncThunk(
  'company/user/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('company/user/', payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        console.log(data)
        const errorMessage = data.message.email[0] || data.message.phone[0]
        return thunkAPI.rejectWithValue({message: errorMessage})
      } 
      }
  }
)

export const getUser = createAsyncThunk(
  'company/user/get',
  async (payload: any = {}, thunkAPI) => {
    try {
      const { search, page, pageSize, sort, order, is_disabled } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
        ...(Object.keys(payload).includes('is_disabled') && { is_disabled: is_disabled }),
      }).toString();
      const response = await api.get(`company/user/?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage })
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  'company/user/patch',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.patch(`company/user/`, payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        console.log(data)
        const errorMessage = data.error.details.email[0] || "An error occurred"
        return thunkAPI.rejectWithValue({message: errorMessage})
      } 
    }
  }
);

const addUserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCreateUsersStatus(state, action) {
      state.createStatus = action.payload
    },
    setUpdateUsersStatus(state, action) {
      state.updateStatus = action.payload
    }
  },
  extraReducers
});




export const { setCreateUsersStatus, setUpdateUsersStatus } = addUserSlice.actions
export default addUserSlice.reducer;
