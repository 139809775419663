import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createExpert, createMultipleExpert, getExpert, getReviewers, getTaskers, updateExpert} from '../slices/expertSlice/addExpertSlice';
import { STATUS } from '../../utils/status';
import { ExpertTypeState} from '../interfaces/interface';

const ExtraReducers = (builder: ActionReducerMapBuilder<ExpertTypeState>) => {
  builder
    .addCase(createExpert.pending, (state) => {
      state.createStatus = STATUS.PENDING;
      state.createExpertMessage = null
    })
    .addCase(createExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload; 
      state.createStatus = STATUS.SUCESS;
      console.log(action.payload)
      state.createExpertMessage = action.payload?.message || "Success"
    })
    .addCase(createExpert.rejected, (state, action:any) => { 
      state.createStatus = STATUS.FAILED;
      console.log(action.payload)
      state.createExpertMessage = action.payload?.message || "An error occurred"
    })
    .addCase(createMultipleExpert.pending, (state) => {
      state.createMultipleStatus = STATUS.PENDING;
      state.createMultipleExpertMessage = null
    })
    .addCase(createMultipleExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload;
      state.createMultipleStatus = STATUS.SUCESS;
      state.createMultipleExpertMessage = null
    })
    .addCase(createMultipleExpert.rejected, (state, action:any) => { 
      state.createMultipleStatus = STATUS.FAILED;
      state.createMultipleExpertMessage = action.payload?.message || "An error occurred"
    })
    .addCase(getExpert.pending, (state) => {
      state.getStatus = STATUS.PENDING;
      state.getExperMessage = null
    })
    .addCase(getExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload; 
      state.getStatus = STATUS.SUCESS; 
      state.getExperMessage = null
    })
    .addCase(getExpert.rejected, (state, action:any) => { 
      state.getStatus = STATUS.FAILED;
      state.getExperMessage = action.payload?.message || "An error occurred"
    })
    .addCase(updateExpert.pending, (state) => {
      state.updateStatus = STATUS.PENDING;
      state.updateExpertMessage = null
    })
    .addCase(updateExpert.fulfilled, (state, action: PayloadAction<any>) => {
      const updateExpert = action.payload;
      state.experts.results = state.experts.results.map((expert: any) =>
        expert.id === updateExpert.id ? updateExpert : expert
      );
      state.updateStatus = STATUS.SUCESS;
      state.updateExpertMessage = null
    })
    .addCase(updateExpert.rejected, (state, action:any) => {
      state.updateStatus = STATUS.FAILED;
      state.updateExpertMessage = action.payload?.message || "An error occurred"
    })

    .addCase(getReviewers.pending, (state) => {
      state.getReviewerStatus = STATUS.PENDING;
      state.getReviewersMessage = null
    })
    .addCase(getReviewers.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.getReviewerStatus = STATUS.SUCESS;
      state.reviewers = action.payload; // Ensure this matches the useSelector
      state.getReviewersMessage = null
    })
    .addCase(getReviewers.rejected, (state, action: any) => {
      state.getReviewerStatus = STATUS.FAILED;
      state.getReviewersMessage = action.payload?.message || "An error occurred"
    })

    .addCase(getTaskers.pending, (state, action) => {
      state.getTaskersStatus = STATUS.PENDING;
      state.getTaskersMessage = null
    })
    .addCase(getTaskers.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.getTaskersStatus = STATUS.SUCESS;
      state.taskers = action.payload
      state.getTaskersMessage = null

    })
    .addCase(getTaskers.rejected, (state, action:any) => {
      state.getTaskersStatus = STATUS.FAILED
      state.getTaskersMessage = action.payload?.message || "An error occurred"
    })
};

export default ExtraReducers;
