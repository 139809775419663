import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUser, setCreateUsersStatus, setUpdateUsersStatus, updateUser } from '../../redux/slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { FormField } from '../inputField/textfield';
import { UserRoleSelect } from '../inputField/selectField';
import useStyles from '../../assets/styles/style';

interface FormState {
  id?: string;
  first_name: string;
  email: string;
  role: string;
  last_name: string;
  phone: string;
}

const UserForm = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { userDetails: initialUserDetails, id: userId } = location.state || { userDetails: null, id: '' };
  const [userDetails, setUserDetails] = useState<FormState>({ first_name: '', email: '', role: '', phone: '', last_name: '' });
  const createStatus = useSelector((state: RootState) => state.users.createStatus);
  const updateStatus = useSelector((state: RootState) => state.users.updateStatus);
  const createUserMessage = useSelector((state: RootState) => state.users.createUserMessage)
  const updateUserMessage = useSelector((state: RootState) => state.users.updateMessage)

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  useEffect(() => {
    if (initialUserDetails) {
      setUserDetails(initialUserDetails);
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialUserDetails]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => {      
      if (name === 'email') {
        const emailRegex = /^[a-zA-Z0-9@.-]*$/;
        if (!emailRegex.test(value)) {
          return prevDetails;
        }
      }
      return {
        ...prevDetails,
        [name]: value.trim(),
      };
    });
  };
  

  const handleSelectFieldChange = (event: ChangeEvent<{ value: unknown }>, fieldName: keyof FormState) => {
    const value = event.target.value as string;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value.trim(),
    }));
  };

  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; 
    if (name === 'phone' && !/^\d{0,10}$/.test(value)) {
      return;
    }
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value.trim(),
    }));
  };

  
  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};

    if (!userDetails?.email?.trim()) {
      errors.email = 'Email is Required';
    } else if (!isValidEmail(userDetails.email)) {
      errors.email = 'Invalid email format';
    }

    if (!userDetails?.first_name?.trim()) {
      errors.first_name = 'First Name is Required';
    }
    if (!userDetails?.last_name?.trim()) {
      errors.last_name = 'Last Name is Required';
    }
    if (!userDetails?.role?.trim()) {
      errors.role = 'Role is Required';
    }
    
    // if (!userDetails?.phone?.trim()) {
    //   errors.phone = 'Phone Number is Required';
    // } 
    // else if (!isValidPhoneNumber(userDetails.phone)) {
    //   errors.phone = 'Invalid phone number (must be 10 digits)';
    // }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields correctly.', 'warning');
      return;
    }

    if (userId) {
      const updatedFields = Object.keys(userDetails).reduce((acc, key) => {
        if ((userDetails as any)[key] !== (initialUserDetails as any)[key]) {
          (acc as any)[key] = (userDetails as any)[key];
        }
        return acc;
      }, {} as Partial<FormState>);

      dispatch(updateUser({ id: userId, ...updatedFields }));
    } else {
      const payload = {
        id: userDetails.id,
        first_name: userDetails.first_name,
        email: userDetails.email,
        role: userDetails.role,
        last_name: userDetails.last_name,
        ...(userDetails.phone ? {phone: userDetails.phone} : {})
      };
      dispatch(createUser(payload));
    }
  };

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar(updateUserMessage, 'success');
        setTimeout(() => {
          navigate('/users');
        }, 1000);
      }
  
      if (updateStatus === STATUS.FAILED) {
        handleSnackbar(updateUserMessage, 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      if(updateStatus === STATUS.SUCESS) {
        dispatch(setUpdateUsersStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  useEffect(() => {
    const handleCreateStatus = () => {
    if (createStatus === STATUS.SUCESS) {
      handleSnackbar(createUserMessage, 'success');
      setTimeout(() => {
        navigate('/users');
      }, 1000);
    }
    if (createStatus === STATUS.FAILED) {
        handleSnackbar(createUserMessage, 'warning');
    }
    }
    handleCreateStatus();
    return () => {
      if(createStatus === STATUS.SUCESS || createStatus === STATUS.FAILED) {
        dispatch(setCreateUsersStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]); 

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} className={classes.formContainer}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormField
            label="First Name"
            placeholder="Enter First Name"
            name="first_name"
            value={userDetails.first_name}
            onChange={handleFieldChange}  
            error={formSubmitted && formErrors.first_name ? formErrors.first_name : ''}
          />
          <FormField
            label="Last Name"
            placeholder="Enter Last Name"
            name="last_name"
            value={userDetails.last_name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.last_name ? formErrors.last_name : ''}
          />
          <FormField
            label="Email ID"
            placeholder="Enter Email ID"
            name="email"
            value={userDetails.email}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.email ? formErrors.email : ''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <FormField
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="phone"
            value={userDetails.phone}
            onChange={handlePhoneFieldChange}
            typographySx={{ marginTop: '20px'}}
            error={formSubmitted && formErrors.phone ? formErrors.phone : ''}
          />
          <UserRoleSelect
            label="Role"
            value={userDetails.role}
            onChange={(e:any) => handleSelectFieldChange(e, 'role')}
            options={[
              { value: 'ADMIN', label: 'Admin' },
              { value: 'VIEWER', label: 'Viewer' },
              { value: 'EDITOR', label: 'Editor' },
            ]}
            typographySx={{ marginTop: '20px', marginLeft:'6%'}}
            textFieldSx={{marginLeft:'6%', width:'105%'}}
            error={formSubmitted && formErrors.role ? formErrors.role : ''}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{
            fontSize: '14px',
            textTransform: 'none',
            color: '#FFFFFF',
            width: '100%',
            backgroundColor: '#3D85C6',
            borderRadius: '8px',
            marginTop: '20px',
          }}
          disabled={userId ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING}
          startIcon={userId ? (updateStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null) : (createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null)} 
        >
          <>{userId ? 'Update User' : 'Add User'}</>
        </Button>
      </div>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default UserForm;
