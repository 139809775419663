import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { CompanyState, GetPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/companyExtraReducer';

const initialState: CompanyState = {
  company: {
    message: '',
    data: {
      role: '',
      company_initially_onboarded_as: '',
      actions: {
        read: false,
        create: false,
        update: false,
        delete: false,
        share: false,
      },
      email: '',
      modules: {
        dashboard: null,
        admin: {
          table: {
            access: false,
            table_fields: [],
            table_actions: [],
            search: {
              access: false,
            },
          },
        },
        experts: {
          table: {
            access: false,
            table_fields: [],
            table_actions: [],
            search: {
              access: false,
            },
          },
        },
        projects: {
          table: {
            access: false,
            table_fields: [],
            table_actions: [],
            search: {
              access: false,
            },
          },
        },
      },
    },
    error: null,
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
  getUserAccessMessage: ""
};

export const getUserAccess = createAsyncThunk(
  'company/user/access/get',
  async (payload: GetPayload = {}, thunkAPI) => {
    try {
      const { search, page, pageSize, sort, order } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
      }).toString();
      const response = await api.get(`company/user/access?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers
});

export default companySlice.reducer;
