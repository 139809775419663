import React, { useRef, useCallback } from 'react';
import { TableRow, Checkbox, Typography, Tooltip } from '@mui/material';
import { TableCellBody } from '../../table/tableCell';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { addId, removeId } from '../../../redux/reducers/taskIdSlice';
import { useParams } from 'react-router-dom';

interface TableRowItemProps {
  row: any;
}

const Type1AllocationTableRow: React.FC<TableRowItemProps> = ({ row }) => {
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const dispatch = useDispatch<AppDispatch>();
  const lastSelectedIdRef = useRef<string | undefined>(undefined);
  const { option } = useParams();
  const words = option && option.split(' ');
  const taskerType = words && words[0];

  const handleCheckboxClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>, id: string) => {
      const checked = (event.target as HTMLInputElement).checked;
      const shiftKey = event.shiftKey;

      lastSelectedIdRef.current = lastSelectedIdRef.current || id;

      console.log(`Checkbox clicked. ID: ${id}, Checked: ${checked}, LastSelectedId: ${lastSelectedIdRef.current}, Shift: ${shiftKey}`);

      if (checked) {
        dispatch(addId({ id, task_name: row.task_name }));
      } else {
        dispatch(removeId(id));
      }

      if (shiftKey && lastSelectedIdRef.current) {
        selectRange(lastSelectedIdRef.current, id);
      }

      lastSelectedIdRef.current = id;
    },
    [dispatch, row.task_name]
  );

  const selectRange = (lastId: string, currentId: string) => {

    const lastIndex = selectedIds.findIndex((item) => item.id === lastId);
    const currentIndex = selectedIds.findIndex((item) => item.id === currentId);

    console.log(`Last Selected Index: ${lastIndex}`);
    console.log(`Current Index: ${currentIndex}`);

    if (lastIndex === -1 || currentIndex === -1) {
      return;
    }

    const idsToSelect: string[] = [];
    const rangeStart = Math.min(lastIndex, currentIndex);
    const rangeEnd = Math.max(lastIndex, currentIndex);

    for (let i = rangeStart; i <= rangeEnd; i++) {
      idsToSelect.push(selectedIds[i].id);
    }

    console.log(`IDs to Select: ${idsToSelect.join(', ')}`);

    idsToSelect.forEach((id) => {
      if (!selectedIds.some((selected) => selected.id === id)) {
        dispatch(addId({
          id,
          task_name: row.task_name
        }));
      }
    });
  };


  const truncateName = (name: string): string => {
    const maxLength = 30;
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  };

  return (
    <TableRow
      key={row.id}
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
        
      }}
    >
      <TableCellBody>
        <Checkbox
          checked={selectedIds.some(selected => selected.id === row.id)}
          onClick={(event) => handleCheckboxClick(event as React.MouseEvent<HTMLInputElement, MouseEvent>, row.id)}
          size='small'
        />
      </TableCellBody>
      <TableCellBody>
        <Tooltip title={row.task_name ? row.task_name : '-'}>
          <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
            {truncateName(row.task_name ? row.task_name : '-')}
          </div>
        </Tooltip>
      </TableCellBody>
      {taskerType === 'Task' &&
        <TableCellBody>{row.task_allocations.length > 0 ? row.task_allocations[0].status : 'NOT ALLOCATED'}</TableCellBody> 
      }
      {taskerType === 'Review' &&
        <TableCellBody>{row.review_allocations.length > 0 ? row.review_allocations[0].status : 'NOT ALLOCATED'}</TableCellBody> 
      }

     {taskerType === 'Task' &&
      <TableCellBody>
        {row.task_allocations.length > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {row.task_allocations.map((allocation: { id: string; pseudo_name: string; }) => (
              <Typography
                key={allocation.id}
                sx={{ color: 'black' }}
              >
                {allocation.pseudo_name || '-'}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>-</Typography>
        )}
      </TableCellBody>
      }
      {taskerType === 'Review' &&
      <TableCellBody>
        {row.review_allocations.length > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {row.review_allocations.map((allocation: { id: string; pseudo_name: string; }) => (
              <Typography
                key={allocation.id}
                sx={{ color: 'black' }}
              >
                {allocation.pseudo_name || '-'}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>-</Typography>
        )}
      </TableCellBody>
      }

      {taskerType === "Review" ? (<TableCellBody>
        {row.review_allocations.length > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {row.review_allocations.map((allocation: { id: string; name: string; email: string }) => (
              <Typography
                key={allocation.id}
                sx={{ color: 'black' }}
              >
                {(allocation.name && allocation.name.trim() ? allocation.name : allocation.email)}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>-</Typography>
        )}
      </TableCellBody>) : (<TableCellBody>
        {row.task_allocations.length > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {row.task_allocations.map((allocation: { id: string; name: string; email: string }) => (
              <Typography
                key={allocation.id}
                sx={{ color: 'black' }}
              >
                {(allocation.name && allocation.name.trim() ? allocation.name : allocation.email)}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>-</Typography>
        )}
      </TableCellBody>)}




    </TableRow>
  );
};

export default Type1AllocationTableRow;
