import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, TextField, Autocomplete} from '@mui/material';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

interface PaginationProps {
  itemsPerPage: number;
  onChangeRowsPerPage: any;
  handleOnChange?: any
}

const PaginationEntries: React.FC<PaginationProps> = ({ itemsPerPage, onChangeRowsPerPage, handleOnChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDebounce, setIsDebounce] = useState(false)
  

  const location = useLocation();
  const isProjectsPath = location.pathname.includes('/project');
  const data = [20,50,100,500]

  const debouncedResults = useMemo(() => {
    setIsDebounce(true)
    return debounce((e) => onChangeRowsPerPage(e), 500)
    
  }, [onChangeRowsPerPage]);

  useEffect(() => {
    return () => {
      setIsDebounce(false)
      debouncedResults.cancel();
    };  
  });

  // useEffect(() => {
  //   setOpenDropdown(String(itemsPerPage) == "10" ? false : data.find((d) => d.includes(String(itemsPerPage))) ? true : false)
  // }, [itemsPerPage])

  


  return (
    <div style={{ display: 'flex', marginRight: '20px', alignItems: 'center' }}>
      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Show</span>
      <FormControl sx={{ m: 1, minWidth: 10 }}>

      <Autocomplete
          // disablePortal
          id="combo-box-demo"
          options={data}
          value={itemsPerPage}
          disabled={isDebounce}
          sx={{ width: 110 }}
          size='small'      
          renderInput={(params) => (
            <TextField
            type='number'
            {...params}
              // onChange={(e:any) =>  debouncedResults(e.target.value)}
              onChange={(e:any) =>  debouncedResults(e.target.value)}

              sx={{
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                }
              }}
            />
          )}
          onChange={(e:any, newValue:any) => debouncedResults(newValue)}

          // open={data.includes(itemsPerPage)}
        />
      </FormControl>

      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Rows</span>
    </div>
  );
};

export default PaginationEntries;
