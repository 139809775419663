import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsPayload, AnalyticsState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/analyticsExtraReducer';

const initialState: AnalyticsState = {
  analytics: {
    data: {
      total_tasks_completed: 0,
      total_tasks_added: 0,
      total_tasks_allocated: 0,
      total_reviews_allocated: 0,
      total_reviews_completed: 0
    },
    message: "",
    status: undefined
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
  getAnalyticsMessage: ""
};

export const getAnalytics = createAsyncThunk(
  '/task/company/analytics/get',
  async (payload: AnalyticsPayload = { association_type: '' }, thunkAPI) => {
    try {
      const { search, page, pageSize, sort_by, sort_order, project_id, type, association_type } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort_by && { sort_by }),
        ...(sort_order && { sort_order }),
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(association_type && { association_type })
      }).toString();
      const response = await api.get(`/task/company/analytics?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({ errorMessage });
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
  },
  extraReducers
});

// export const {} = analyticsSlice.actions;
export default analyticsSlice.reducer;
