import { ActionReducerMapBuilder, current, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AllocationState } from '../interfaces/interface';
import { createAllocation } from '../slices/allocation';

const ExtraReducers = (builder: ActionReducerMapBuilder<AllocationState>) => {
  builder
    .addCase(createAllocation.pending, (state) => {
      state.createStatus = STATUS.PENDING;
      state.createError = null
    })
    .addCase(createAllocation.fulfilled, (state, action: PayloadAction<any>) => {
      console.log({action, state:current(state)})
      state.allocation = action.payload; 
      state.createStatus = STATUS.SUCESS; 
      state.createError = null
    })
    .addCase(createAllocation.rejected, (state, action:any) => { 
      state.createStatus = STATUS.FAILED;
      state.createError = action.payload?.message || "An error occurred"
    })
};

export default ExtraReducers;
