import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { AddButton } from "../../button/button";
import { Autocomplete, Checkbox, CircularProgress, Divider, List, ListItem, ListItemText, TextField, Typography, } from "@mui/material";
import SnackbarNotification from "../../snackbar/snackbarNotification";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/appStore";
import { STATUS } from "../../../utils/status";
import { deleteVendorAction, fetchVendorsAction, getVendorAction, onBoardNewVendorAndAdminAction, setOnBoardNewVendorAndAdminStatus, setUpdateVendorAndAdminStatus, updateVendorAction } from "../../../redux/slices/vendor";
import VendorSearchBar from "./VendorSearchBar";
import VendorPermissions from "./VendorPermissions";
import AdminPermissions from "../admins/AdminPermissions";

interface DrawerProps {
    setIsDrawerOpen?: any,
    isDrawerOpen?: boolean,
    selectedRow?: any,
    isRowClicked?: any
}

const VendorDrawerContent: React.FC<DrawerProps> = ({ setIsDrawerOpen, isDrawerOpen, selectedRow, isRowClicked }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState({
        "export_data": [false],
        "project_analytics": [false],
        "task_allocation_single": [false],
        "task_allocation_bulk": [false],
        "task_unallocation_single": [false],
        "task_unallocation_bulk": [false],
        "review_allocation_single": [false],
        "review_allocation_bulk": [false],
        "review_unallocation_single": [false],
        "review_unallocation_bulk": [false],
        "show_completed_task_details": [false],
    })
    const [vendorId, setVendorId] = useState("")
    const { id } = useParams();
    const updateVendorStatus = useSelector((state: RootState) => state.vendors.deleteVendorStatus)
    const updateVendorMessage = useSelector((state: RootState) => state.vendors.updateVendorMessage)
    const Vendors = useSelector((state: RootState) => state.vendors.vendors)
    const getStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)
    const associationType = useSelector((state: RootState) => state.projects.association_type)
    const createVendorStatus = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminStatus);
    const createVendorMessage = useSelector((state: RootState) => state.vendors.createVendorMessage)
    const onBoardNewVendorAndAdminMessage = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminMessage)
    const [selectedVendor, setSelectedVendor] = useState<any | null>(null);
    const [isAddVendorBtnEnable, setIsAddVendorBtnEnable] = useState(false)

    const Permissions = useSelector((state: RootState) => state.projects.permissions)
    const reviewAllocationSingle = Permissions?.review_allocation_single?.[0]
    const reviewAllocationBulk = Permissions?.review_allocation_bulk?.[0]
    const reviewUnallocationSingle = Permissions?.review_unallocation_single?.[0]
    const reviewUnallocationBulk = Permissions?.review_unallocation_bulk?.[0]

    // console.log(reviewAllocationSingle)
    // console.log(Permissions)
    // console.log("sdvdfbdfvs")




    useEffect(() => {
        dispatch(fetchVendorsAction({ project_id: id }));
    }, [selectedRow])

    useEffect(() => {
        if (selectedRow && selectedRow.vendor_id) {
            setVendorId(selectedRow.vendor_id);
            setSelectedVendor({
                vendor_id: selectedRow.vendor_id,
                vendor_name: selectedRow.vendor_name,
                vendor_pseudo_name: selectedRow.vendor_pseudo_name || selectedRow.pseudo_name || "",
            });
            setCheckedItems({
                export_data: [selectedRow.permissions?.export_data?.[0] || false],
                project_analytics: [selectedRow.permissions?.project_analytics?.[0] || false],
                task_allocation_single: [selectedRow.permissions?.task_allocation_single?.[0] || false],
                task_allocation_bulk: [selectedRow.permissions?.task_allocation_bulk?.[0] || false],
                task_unallocation_single: [selectedRow.permissions?.task_unallocation_single?.[0] || false],
                task_unallocation_bulk: [selectedRow.permissions?.task_unallocation_bulk?.[0] || false],
                review_allocation_single: [selectedRow.permissions?.review_allocation_single?.[0] || false],
                review_allocation_bulk: [selectedRow.permissions?.review_allocation_bulk?.[0] || false],
                review_unallocation_single: [selectedRow.permissions?.review_unallocation_single?.[0] || false],
                review_unallocation_bulk: [selectedRow.permissions?.review_unallocation_bulk?.[0] || false],
                show_completed_task_details: [selectedRow.permissions?.show_completed_task_details?.[0] || false],
            });
        }
    }, [selectedRow]);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.trim());
    };

    const debouncedResults = useMemo(() => { return debounce(handleSearchChange, 300); }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (
        message: string,
        severity: "success" | "error" | "info" | "warning"
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        dispatch(getVendorAction({
            search_query: searchTerm,
        }));
    }, [dispatch, searchTerm]);

    const handleAutocompleteInput = (e: any) => {
        debouncedResults(e)
    };

    useEffect(() => {
        if (getStatus === STATUS.SUCESS) {
            setOptions(Vendors.results)
        }
    }, [getStatus])

    const handleAddVendor = () => {
        if (selectedRow) {
            dispatch(deleteVendorAction({
                id: selectedRow.id,
                permissions: checkedItems,
            }))
        }
        else {  
            dispatch(onBoardNewVendorAndAdminAction({
                type: "VENDOR",
                id: vendorId,
                permissions: checkedItems,
                project_id: id
            }))
        }

    }

    useEffect(() => {
        const handleCreateVendorStatus = () => {
            if (createVendorStatus === STATUS.SUCESS) {
                handleSnackbar(onBoardNewVendorAndAdminMessage, 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchVendorsAction({ project_id: id }))
            }
            if (createVendorStatus === STATUS.FAILED) {
                handleSnackbar(onBoardNewVendorAndAdminMessage, 'error');
            }
        }
        handleCreateVendorStatus();
        return () => {
            dispatch(setOnBoardNewVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [createVendorStatus]);

    useEffect(() => {
        const handleUpdateVendorStatus = () => {
            if (updateVendorStatus === STATUS.SUCESS) {
                handleSnackbar(updateVendorMessage, 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchVendorsAction({ project_id: id }))
            }
            if (updateVendorStatus === STATUS.FAILED) {
                handleSnackbar(updateVendorMessage, 'error');
            }
        }
        handleUpdateVendorStatus();
        return () => {
            dispatch(setUpdateVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [updateVendorStatus]);



    useEffect(() => {
        const hasPermissionSelected = Object.values(checkedItems).some(
            (permissionArray) => permissionArray.includes(true)
        );

        setIsAddVendorBtnEnable(hasPermissionSelected)
    }, [checkedItems]);


    //! Admin Functions

    // useEffect(() => {
    //     if (selectedRow && selectedRow.user_id) {
    //         setAdminId(selectedRow.user_id);
    //         setSelectedAdmin({
    //             admin_id: selectedRow.user_id,
    //             first_name: selectedRow.user_first_name,
    //             email: selectedRow.user_email,
    //         });
    //         setCheckedItemsAdmin({
    //             export_data: [selectedRow.permissions.export_data?.[0] || false],
    //             project_analytics: [selectedRow.permissions.project_analytics?.[0] || false],
    //             task_allocation: [selectedRow.permissions.task_allocation?.[0] || false],
    //             review_allocation: [selectedRow.permissions.review_allocation?.[0] || false],
    //             project_details: [selectedRow.permissions.project_details?.[0] || false],
    //             project_config: [selectedRow.permissions.project_config?.[0] || false],
    //             project_payments: [selectedRow.permissions.project_payments?.[0] || false],
    //             project_users: [selectedRow.permissions.project_users?.[0] || false],
    //         });
    //     }
    // }, [selectedRow]);

    // const handleCheckboxChangeAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, checked } = event.target;

    //     setCheckedItemsAdmin((prev) => {
    //         switch (name) {
    //             case "allowAll":
    //                 return {
    //                     project_analytics: [checked],
    //                     export_data: [checked],
    //                     task_allocation: [checked],
    //                     review_allocation: [checked],
    //                     project_details: [checked],
    //                     project_config: [checked],
    //                     project_payments: [checked],
    //                     project_users: [checked]
    //                 };

    //             case "allowTasking":
    //                 return {
    //                     ...prev,
    //                     task_allocation: [checked],
    //                     review_allocation: [checked],
    //                 };

    //             case "allowExportData":
    //                 return {
    //                     ...prev,
    //                     export_data: [checked],
    //                 };

    //             case "tasking":
    //                 return {
    //                     ...prev,
    //                     task_allocation: [checked],
    //                 };

    //             case "reviewing":
    //                 return {
    //                     ...prev,
    //                     review_allocation: [checked],
    //                 };

    //             case "allowProjetAnalytics":
    //                 return {
    //                     ...prev,
    //                     project_analytics: [checked],
    //                 };

    //             case "projectDetails":
    //                 return {
    //                     ...prev,
    //                     project_details: [checked],
    //                 };

    //             case "projectUsers":
    //                 return {
    //                     ...prev,
    //                     project_users: [checked],
    //                 }

    //             case "projectConfigs":
    //                 return {
    //                     ...prev,
    //                     project_config: [checked],
    //                 };

    //             case "paymentSettings":
    //                 return {
    //                     ...prev,
    //                     project_payments: [checked],
    //                 };

    //             case "viewCompletedTaskDetails":
    //                 return {
    //                     ...prev,
    //                     show_completed_task_details: [checked],
    //                 };
    //             default:
    //                 return prev;
    //         }
    //     });
    // };



    // const handleAddAdmin = () => {
    //     if (selectedRow) {
    //         dispatch(deleteVendorAction({
    //             id: selectedRow.id,
    //             permissions: checkedItems,
    //         }))
    //     }
    //     else {
    //         dispatch(onBoardNewVendorAndAdminAction({
    //             type: "COMPANY",
    //             id: adminId,
    //             project_id: id,
    //             permissions: checkedItems
    //         }))
    //     }
    // }

    return (
        <>
            <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>{selectedRow ? 'Edit Vendor' : 'Add Vendor'}</Typography>
            <Divider />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "20px",
                }}
            >

                <VendorSearchBar
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    options={options}
                    selectedRow={selectedRow}
                    selectedVendor={selectedVendor}
                    setVendorId={setVendorId}
                    setSelectedVendor={setSelectedVendor}
                    loading={loading}
                    handleAutocompleteInput={handleAutocompleteInput}
                />
            </div>

            {associationType === "COMPANY" ? (
                <VendorPermissions
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    handleAddVendor={handleAddVendor}
                    createVendorStatus={createVendorStatus}
                    selectedRow={selectedRow}
                    vendorId={vendorId}
                    isAddVendorBtnEnable={isAddVendorBtnEnable}
                />) : (<></>)}


            <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                <AddButton onClick={handleAddVendor} disabled={createVendorStatus === STATUS.PENDING || !vendorId || !isAddVendorBtnEnable}>
                    {createVendorStatus === STATUS.PENDING ? (
                        <CircularProgress size={21} sx={{ mr: 1 }} />
                    ) : ('')}
                    {selectedRow ? 'Edit Vendor' : 'Add Vendor'}
                </AddButton>
            </div>


            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default VendorDrawerContent