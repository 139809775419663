import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { DeliveredState } from '../interfaces/interface';
import { createUploadDeliveredFile } from '../slices/deliveredFile';

const ExtraReducers = (builder: ActionReducerMapBuilder<DeliveredState>) => {
  builder
  .addCase(createUploadDeliveredFile.pending, (state) => {
    state.createDeliveredStatus = STATUS.PENDING;
    state.createUploadDeliveredFileMessage = null
  })
  .addCase(createUploadDeliveredFile.fulfilled, (state, action) => {
    state.createDeliveredStatus = STATUS.SUCESS;
    state.delivered = action.payload;
    state.createUploadDeliveredFileMessage = null
  })
  .addCase(createUploadDeliveredFile.rejected, (state, action:any) => {
    state.createDeliveredStatus = STATUS.FAILED;
    state.createUploadDeliveredFileMessage = action.payload?.message || "An error occurred"
  });
};

export default ExtraReducers;
