import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../../assets/styles/listTableStyle";
import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { AddButton } from "../../button/button";
import { Autocomplete, Checkbox, CircularProgress, Divider, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import SnackbarNotification from "../../snackbar/snackbarNotification";
import { getUser } from "../../../redux/slices/userSlice/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/appStore";
import { STATUS } from "../../../utils/status";
import { deleteVendorAction, fetchAdminsAction, fetchVendorsAction, onBoardNewVendorAndAdminAction, setOnBoardNewVendorAndAdminStatus, setUpdateVendorAndAdminStatus } from "../../../redux/slices/vendor";
import AdminSearchBar from "./AdminSearchBar";
import AdminPermissions from "./AdminPermissions";
import VendorPermissions from "../vendors/VendorPermissions";
import VendorSearchBar from "../vendors/VendorSearchBar";

interface DrawerProps {
    setIsDrawerOpen: any,
    isDrawerOpen: any,
    selectedRow?: any,
    isRowClicked?: any
}
interface CheckedItems {
    project_analytics: boolean[];
    export_data: boolean[];
    task_allocation: boolean[];
    review_allocation: boolean[];
    project_details: boolean[];
    project_config: boolean[];
    project_payments: boolean[];
    project_users: boolean[];
    completing_task_action_details: any[];
}

const AdminDrawerContent: React.FC<DrawerProps> = ({ setIsDrawerOpen, isRowClicked, selectedRow }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [adminId, setAdminId] = useState("")
    const [checkedItems, setCheckedItems] = useState<CheckedItems>({
        project_analytics: [false],
        export_data: [false],
        task_allocation: [false],
        review_allocation: [false],
        project_details: [false],
        project_config: [false],
        project_payments: [false],
        project_users: [false],
        completing_task_action_details: [false]
    });

    const [checkedItemsVendor, setCheckedItemsVendor] = useState({
        "export_data": [false],
        "project_analytics": [false],
        "task_allocation_single": [false],
        "task_allocation_bulk": [false],
        "task_unallocation_single": [false],
        "task_unallocation_bulk": [false],
        "review_allocation_single": [false],
        "review_allocation_bulk": [false],
        "review_unallocation_single": [false],
        "review_unallocation_bulk": [false],
        "show_completed_task_details": [false],
    })

    const users = useSelector((state: RootState) => state.users.users);
    const updateAdminStatus = useSelector((state: RootState) => state.vendors.deleteVendorStatus)
    const getStatus = useSelector((state: RootState) => state.users.getStatus)
    const AdminStatus = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminStatus)
    const associationType = useSelector((state: RootState) => state.projects.association_type)
    const { id } = useParams()
    const [selectedAdmin, setSelectedAdmin] = useState<any | null>(null);
    const [isAddVendorBtnEnable, setIsAddVendorBtnEnable] = useState(false)
    const association_type = useSelector((state: RootState) => state.projects.association_type);
    const [selectedVendor, setSelectedVendor] = useState<any | null>(null);
    const updateAdminMessage = useSelector((state: RootState) => state.vendors.createVendorMessage)
    const createAdminMessage = useSelector((state: RootState) => state.vendors.createVendorMessage)
    const onBoardNewVendorAndAdminActionMessage = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminMessage)

    //! For Vendor Status
    const [vendorId, setVendorId] = useState("")

    const createVendorStatus = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminStatus);

    useEffect(() => {
        dispatch(fetchAdminsAction({ project_id: id }));
    }, [selectedRow])

    useEffect(() => {
        if (selectedRow && selectedRow.user_id) {
            setAdminId(selectedRow.user_id);
            setSelectedAdmin({
                admin_id: selectedRow.user_id,
                first_name: selectedRow.user_first_name,
                email: selectedRow.user_email,
            });
            setCheckedItems({
                export_data: [selectedRow.permissions.export_data?.[0] || false],
                project_analytics: [selectedRow.permissions.project_analytics?.[0] || false],
                task_allocation: [selectedRow.permissions.task_allocation?.[0] || false],
                review_allocation: [selectedRow.permissions.review_allocation?.[0] || false],
                project_details: [selectedRow.permissions.project_details?.[0] || false],
                project_config: [selectedRow.permissions.project_config?.[0] || false],
                project_payments: [selectedRow.permissions.project_payments?.[0] || false],
                project_users: [selectedRow.permissions.project_users?.[0] || false],
                completing_task_action_details: selectedRow.permissions.completing_task_action_details || [false]
            });
        }
    }, [selectedRow]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.trim());
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleSearchChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (
        message: string,
        severity: "success" | "error" | "info" | "warning"
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        dispatch(getUser({
            search: searchTerm,
            is_disabled: false
        }));
    }, [dispatch, searchTerm]);


    const handleAutocompleteInput = (e: any) => {
        debouncedResults(e)
    };

    useEffect(() => {
        if (getStatus === STATUS.SUCESS) {
            setOptions(users.results)
        }
    }, [getStatus])


    const handleAddAdmin = () => {
        if (selectedRow) {
            dispatch(deleteVendorAction({
                id: selectedRow.id,
                permissions: association_type == 'COMPANY' ? checkedItems : checkedItemsVendor,
            }))
        }
        else {
            dispatch(onBoardNewVendorAndAdminAction({
                type: association_type,
                id: adminId,
                project_id: id,
                permissions: association_type == 'COMPANY' ? checkedItems : checkedItemsVendor
            }))
        }
    }

    useEffect(() => {
        const handleStatus = () => {
            if (AdminStatus === STATUS.SUCESS) {
                // handleSnackbar('Admin Added to Project Successfully', 'success');
                handleSnackbar(onBoardNewVendorAndAdminActionMessage, "success")
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000)
                dispatch(fetchAdminsAction({ project_id: id }))
            }
            if (AdminStatus === STATUS.FAILED) {
                // handleSnackbar('This Admin already exists on this project', 'error');
                handleSnackbar(onBoardNewVendorAndAdminActionMessage, 'error');
            }
        };

        handleStatus();
        return () => {
            if (AdminStatus === STATUS.SUCESS || AdminStatus === STATUS.FAILED) {
                dispatch(setOnBoardNewVendorAndAdminStatus(STATUS.NOT_STARTED));
            }
        };
    }, [AdminStatus, navigate, dispatch]);

    useEffect(() => {
        const handleAdminVendorStatus = () => {
            if (updateAdminStatus === STATUS.SUCESS) {
                // handleSnackbar('Admin Permissions updated Successfully', 'success');
                handleSnackbar(updateAdminMessage, 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchAdminsAction({ project_id: id }))
            }
            if (updateAdminStatus === STATUS.FAILED) {
                // handleSnackbar('Error occured, please try again.', 'error');
                handleSnackbar(updateAdminMessage, 'error');
            }
        }
        handleAdminVendorStatus();
        return () => {
            if(updateAdminStatus === STATUS.SUCESS || updateAdminStatus === STATUS.FAILED){
                dispatch(setUpdateVendorAndAdminStatus(STATUS.NOT_STARTED));
            }
        };
    }, [updateAdminStatus]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCheckedItems((prev) => {
            switch (name) {
                case "allowAll":
                    return {
                        project_analytics: [checked],
                        export_data: [checked],
                        task_allocation: [checked],
                        review_allocation: [checked],
                        project_details: [checked],
                        project_config: [checked],
                        project_payments: [checked],
                        project_users: [checked],
                        completing_task_action_details: checked ? [true, "read", "edit"] : [false]
                    };


                case "allowTasking":
                    return {
                        ...prev,
                        task_allocation: [checked],
                        review_allocation: [checked],
                    };

                case "allowExportData":
                    return {
                        ...prev,
                        export_data: [checked],
                    };

                case "tasking":
                    return {
                        ...prev,
                        task_allocation: [checked],
                    };

                case "reviewing":
                    return {
                        ...prev,
                        review_allocation: [checked],
                    };

                case "allowProjetAnalytics":
                    return {
                        ...prev,
                        project_analytics: [checked],
                    };

                case "projectDetails":
                    return {
                        ...prev,
                        project_details: [checked],
                    };

                case "projectUsers":
                    return {
                        ...prev,
                        project_users: [checked],
                    }

                case "projectConfigs":
                    return {
                        ...prev,
                        project_config: [checked],
                    };

                case "paymentSettings":
                    return {
                        ...prev,
                        project_payments: [checked],
                    };

                case "viewCompletedTaskDetails":
                    return {
                        ...prev,
                        show_completed_task_details: [checked],
                    };
                case "edit":
                    return {
                        ...prev,
                        completing_task_action_details: checked
                            ? [true, "edit", "read"]
                            : [true, "read"]
                    };

                case "read":
                    return {
                        ...prev,
                        completing_task_action_details: checked
                            ? [true, "read"]
                            : [false]
                    };
                default:
                    return prev;
            }
        });
    };

    useEffect(() => {
        const hasPermissionSelected = Object.values(checkedItems).some((data) => data[0] == true);
        setIsAddVendorBtnEnable(hasPermissionSelected)


    }, [checkedItems])


    useEffect(() => {
        const hasPermissionSelected = Object.values(checkedItemsVendor).some((data) => data[0] == true);
        setIsAddVendorBtnEnable(hasPermissionSelected)
    }, [checkedItemsVendor])

    //! Vendor Functions

    const handleAddVendor = () => {
        if (selectedRow) {
            dispatch(deleteVendorAction({
                id: selectedRow.id,
                permissions: checkedItems,
            }))
        }
        else {
            dispatch(onBoardNewVendorAndAdminAction({
                type: association_type,
                id: adminId,
                permissions: checkedItems,
                project_id: id
            }))
        }
    }

    useEffect(() => {
        dispatch(fetchVendorsAction({ project_id: id }));
    }, [selectedRow])

    useEffect(() => {
        if (selectedRow && selectedRow.user_id) {
            setVendorId(selectedRow.user_id);
            setSelectedVendor({
                admin_id: selectedRow.user_id,
                first_name: selectedRow.user_first_name || "",
                email: selectedRow.user_email,
            });
            setCheckedItemsVendor({
                export_data: [selectedRow.permissions.export_data[0] || false],
                project_analytics: [selectedRow.permissions.project_analytics[0] || false],
                task_allocation_single: [selectedRow.permissions?.task_allocation_single?.[0] || false],
                task_allocation_bulk: [selectedRow.permissions?.task_allocation_bulk?.[0] || false],
                task_unallocation_single: [selectedRow.permissions?.task_unallocation_single?.[0] || false],
                task_unallocation_bulk: [selectedRow.permissions?.task_unallocation_bulk?.[0] || false],
                review_allocation_single: [selectedRow.permissions?.review_allocation_single?.[0] || false],
                review_allocation_bulk: [selectedRow.permissions?.review_allocation_bulk?.[0] || false],
                review_unallocation_single: [selectedRow.permissions?.review_unallocation_single?.[0] || false],
                review_unallocation_bulk: [selectedRow.permissions?.review_unallocation_bulk?.[0] || false],
                show_completed_task_details: [selectedRow.permissions?.show_completed_task_details?.[0] || false],
            });
        }
    }, [selectedRow]);

    const handleCheckboxChangeVendor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCheckedItemsVendor((prev) => {
            switch (name) {
                case "allowAll":
                    return {
                        export_data: [checked],
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],
                        review_allocation_single: [checked],
                        review_allocation_bulk: [checked],
                        review_unallocation_single: [checked],
                        review_unallocation_bulk: [checked],
                        show_completed_task_details: [checked],
                        project_analytics: [checked],
                    };

                case "allowExportData":
                    return {
                        ...prev,
                        export_data: [checked],
                    };

                case "allowProjetAnalytics":
                    return {
                        ...prev,
                        project_analytics: [checked],
                    };

                case "allowTasking":
                    return {
                        ...prev,
                        ...prev,
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],
                    };

                case "singleTaskAllocation":
                case "bulkTaskAllocation":
                    return {
                        ...prev,
                        task_allocation_single: name === "singleTaskAllocation" ? [checked] : prev.task_allocation_single,
                        task_allocation_bulk: name === "bulkTaskAllocation" ? [checked] : prev.task_allocation_bulk,
                    };

                case "singleTaskUnallocation":
                case "bulkTaskUnallocation":
                    return {
                        ...prev,
                        task_unallocation_single: name === "singleTaskUnallocation" ? [checked] : prev.task_unallocation_single,
                        task_unallocation_bulk: name === "bulkTaskUnallocation" ? [checked] : prev.task_unallocation_bulk,
                    };


                case "allowReviewing":
                    return {
                        ...prev,
                        review_allocation_single: [checked],
                        review_allocation_bulk: [checked],
                        review_unallocation_single: [checked],
                        review_unallocation_bulk: [checked],
                    };

                case "singleReviewAllocation":
                case "bulkReviewAllocation":
                    return {
                        ...prev,
                        review_allocation_single: name === "singleReviewAllocation" ? [checked] : prev.review_allocation_single,
                        review_allocation_bulk: name === "bulkReviewAllocation" ? [checked] : prev.review_allocation_bulk,
                    };

                case "singleReviewUnallocation":
                case "bulkReviewUnallocation":
                    return {
                        ...prev,
                        review_unallocation_single: name === "singleReviewUnallocation" ? [checked] : prev.review_unallocation_single,
                        review_unallocation_bulk: name === "bulkReviewUnallocation" ? [checked] : prev.review_unallocation_bulk,
                    };

                case "viewCompletedTaskDetails":
                    return {
                        ...prev,
                        show_completed_task_details: [checked],
                    };
                default:
                    return prev;
            }
        });
    };

    // useEffect(() => {
    //     const hasPermissionSelected = Object.values(checkedItems).some((data) => {
    //         if (typeof data === 'object' && 'edit' in data && 'read' in data) {
    //             return data.edit || data.read; 
    //         }
    //         return false;
    //     });
    //     setIsAddVendorBtnEnable(hasPermissionSelected);

    // }, [checkedItems])

    return (
        <>
            <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
                {selectedRow ? 'Edit Admin' : 'Add Admin'}
            </Typography>
            <Divider />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "20px",
                }}
            >
                <AdminSearchBar
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    options={options}
                    selectedRow={selectedRow}
                    selectedAdmin={selectedAdmin}
                    setAdminId={setAdminId}
                    setSelectedAdmin={setSelectedAdmin}
                    loading={loading}
                    handleAutocompleteInput={handleAutocompleteInput}
                />
            </div>

            {associationType === "VENDOR" ? (
                <VendorPermissions
                    checkedItems={checkedItemsVendor}
                    setCheckedItems={setCheckedItemsVendor}
                    handleCheckboxChange={handleCheckboxChangeVendor}
                    handleAddVendor={handleAddVendor}
                    createVendorStatus={createVendorStatus}
                    selectedRow={selectedRow}
                    vendorId={vendorId}
                    isAddVendorBtnEnable={isAddVendorBtnEnable}

                />

            ) : (<></>)}

            {associationType === "COMPANY" ? (
                <AdminPermissions
                    checkedItems={checkedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    handleAddAdmin={handleAddAdmin}
                    AdminStatus={AdminStatus}
                    adminId={adminId}
                    isAddVendorBtnEnable={isAddVendorBtnEnable}
                    selectedRow={selectedRow}
                />) : (<></>)}

            {associationType === "COMPANY" ? (
                <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                    <AddButton
                        onClick={handleAddAdmin}
                        disabled={createVendorStatus === STATUS.PENDING || !adminId || !isAddVendorBtnEnable}
                    >
                        {createVendorStatus === STATUS.PENDING ? (
                            <CircularProgress size={21} sx={{ mr: 1 }} />
                        ) : ''}
                        {selectedRow ? 'Edit Admin' : 'Add Admin'}
                    </AddButton>
                </div>
            ) : (
                <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                    <AddButton onClick={handleAddAdmin} disabled={createVendorStatus === STATUS.PENDING || !adminId || !isAddVendorBtnEnable}>
                        {createVendorStatus === STATUS.PENDING ? (
                            <CircularProgress size={21} sx={{ mr: 1 }} />
                        ) : ''}
                        {selectedRow ? 'Edit Vendor' : associationType === "VENDOR" ? 'Add Admin' : 'Add Vendor'}
                    </AddButton>
                </div>
            )}



            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    );
};

export default AdminDrawerContent;
